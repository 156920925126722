import React, {useEffect} from 'react';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

import {useDispatch, useSelector} from "react-redux";
import {clearNotification, selectNotificationAlert} from "../../store/NotificationAlertSlice";


const NotificationSnackbar = () => {
    const notification = useSelector(selectNotificationAlert)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (notification.content && notification.content.length > 0) {
            setOpen(true)
        }
    }, [notification])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        dispatch(clearNotification())
    };

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}
                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                  style={{top: '5px'}}
        >
            <Alert
                severity="info"
                sx={{width: '100%'}}>
                <Typography variant="subtitle2">{notification.heading}</Typography>
                <Typography variant="body2">{notification.content}</Typography>
            </Alert>
        </Snackbar>
    );
};

export default NotificationSnackbar;