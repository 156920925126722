import React from 'react';
import {tableButtons, columns, sorting, getTableFeatures, tableMenuItems} from './templates/table'
import {fields} from './templates/dialog'
import ViewContainer from "../../containers/ViewContainer";
import {ENTITIES} from "../../utils/constants";


const EmployeeView = () => {
    return (
        <ViewContainer
            tableTitle={"Сотрудники"}
            viewName={ENTITIES.EMPLOYEE}
            buttons={tableButtons(`${ENTITIES.EMPLOYEE}Dialog`)}
            menuItems={tableMenuItems(`${ENTITIES.EMPLOYEE}Dialog`)}
            fields={fields}
            addTitle={"Добавить сотрудника"}
            editTitle={"Изменить сотрудника"}
            columns={columns}
            sorting={sorting}
            tableFeatures={getTableFeatures()}
            maxWidth="sm"
        />
    );
};

export default EmployeeView;
