import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";

export default function Search({name, entity, params, value, onChange, onSearch}) {

    function handleKeyUp(event) {
        if (event.key === 'Enter') {
            onSearch()
        }
    }

    return (
        <div style={{display: "flex"}}>
            <TextField
                sx={{ml: 1, flex: 1}}
                placeholder="Введите запрос"
                variant="outlined"
                onChange={onChange}
                onKeyUp={handleKeyUp}
                value={value}
            />
            <IconButton
                type="button"
                sx={{p: '10px'}} aria-label="search"
                onClick={onSearch}
            >
                <SearchIcon/>
            </IconButton>
        </div>
    );
}