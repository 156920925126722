import {createSlice} from '@reduxjs/toolkit'
import {DRAWER_WIDTH} from "../utils/constants";

const displaySlice = createSlice({
    name: 'display',
    initialState: {
        size: {},
        navigationDrawer: {
            isOpen: false,
            containerWidth: null
        }
    },

    reducers: {
        setSize: {
            reducer(state, action) {
                state.size = action.payload
                state.navigationDrawer.containerWidth = state.navigationDrawer.isOpen
                    ? action.payload.width - DRAWER_WIDTH
                    : action.payload.width
            },
            prepare(height, width) {
                return {
                    payload: {
                        height, width
                    }
                }
            }
        },

        setNavigationDrawerToggle(state) {
            state.navigationDrawer.isOpen = !state.navigationDrawer.isOpen
            state.navigationDrawer.containerWidth = state.navigationDrawer.isOpen ? state.size.width - DRAWER_WIDTH : state.size.width
        }

    }
});

export const selectNavigationDrawerIsOpen = (state) => {
    return state.display.navigationDrawer.isOpen
};

export const selectViewSize = (state) => {
    return state.display.size
};



export const {setSize, setNavigationDrawerToggle} = displaySlice.actions;

export default displaySlice.reducer;
