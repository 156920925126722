import React, {useEffect} from 'react';
import AdvancedDataTable from "./Table/AdvancedDataTable";
import AdvancedDialog from "./Dialog/AdvancedDialog";
import {setTableTitle} from "../store/MainBarSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectViewSize} from "../store/DisplaySlice";
import {setParentParams} from "../store/DataSlice";
import MainDrawer from "../components/MainDrawer";


const ViewContainer = ({
                           tableTitle, viewName, addTitle, editTitle, columns, buttons, menuItems,
                           sorting, fields, maxWidth, tabs, parentParams, tableFeatures
                       }) => {
    const viewSize = useSelector(selectViewSize);
    const dispatch = useDispatch();

    useEffect(() => {
        if (tableTitle && !parentParams) {
            dispatch(setTableTitle({tableTitle, viewName}))
            localStorage.setItem('currentView', JSON.stringify({tableTitle, viewName}))
        }
        if (parentParams) {
            dispatch(setParentParams(viewName + 'Table', parentParams))
        }
    }, [])

    return (
        <MainDrawer>
                <AdvancedDataTable
                    name={`${viewName}Table`}
                    buttons={buttons}
                    menuItems={menuItems}
                    columns={columns}
                    sorting={sorting}
                    entity={viewName}
                    dialogName={`${viewName}Dialog`}
                    parentParams={parentParams}
                    tableFeatures={tableFeatures}
                />
                <AdvancedDialog
                    name={`${viewName}Dialog`}
                    fields={fields}
                    addTitle={addTitle}
                    editTitle={editTitle}
                    entity={viewName}
                    maxWidth={maxWidth}
                    tabs={tabs}
                    parentParams={parentParams}
                />
        </MainDrawer>
    );
};

export default ViewContainer;
