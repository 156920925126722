import React from "react";
import TaskView from "../../Tasks";
import {ENTITIES, TYPES} from "../../../utils/constants";
import ExecutionPlanView from "../../ExecutionPlan";
import ExecutionFactView from "../../ExecutionFact";
import PaymentPlanView from "../../PaymentPlan";
import PaymentFactView from "../../PaymentFact";
import DetailsView from "../../Details";


export const fields = [
    {
        name: "number",
        label: "Номер",
        grid: {xs: 4, sm: 4}
    },
    {
        name: "customer",
        label: "Контрагент",
        type: TYPES.SELECT,
        entityName: ENTITIES.CUSTOMER,
        grid: {xs: 4, sm: 4}
    },
    {
        name: "status",
        label: "Статус",
        type: TYPES.SELECT,
        entityName: ENTITIES.STATUS,
        grid: {xs: 4, sm: 4}
    },
    {
        name: "signingDate",
        label: "Дата подписания",
        type: TYPES.DATE,
        grid: {xs: 4, sm: 4}
    },
    {
        name: "type",
        label: "Вид",
        type: TYPES.SELECT,
        entityName: ENTITIES.TYPE,
        grid: {xs: 4, sm: 4}
    },
    {
        name: "startExecutionDate",
        label: "Начало действия",
        type: TYPES.DATE,
        rowBreakBefore: true,
        grid: {xs: 4, sm: 4}
    },
    {
        name: "endExecutionDate",
        label: "Окончание действия",
        type: TYPES.DATE,
        grid: {xs: 4, sm: 4}
    },
    {
        name: "sendExecutionDateDS",
        label: "Окончание действия по ДС",
        type: TYPES.DATE,
        grid: {xs: 4, sm: 4}
    },
    {
        name: "startWorkDate",
        label: "Начало выполнения",
        type: TYPES.DATE,
        grid: {xs: 4, sm: 4}
    },
    {
        name: "endWorkDate",
        label: "Окончание выполнения",
        type: TYPES.DATE,
        grid: {xs: 4, sm: 4}
    },
    {
        name: "description",
        label: "Предмет договора",
        rowBreakBefore: true,
        multiline: true,
        grid: {xs: 8, sm: 8}
    },
    {
        name: "subpo",
        label: "% привлечения СубПО",
        type: TYPES.NUMBER,
        interval: {max: 100},
        allowNegative: false,
        grid: {xs: 4, sm: 4}
    },


    ///////////-----------detail tab
    {
        name: "file",
        label: "Файл",
        type: TYPES.FILE,
        additional: true,
    },
    {
        name: "scan",
        label: "Скан",
        type: TYPES.FILE,
        additional: true,
    },
    {
        name: "amount",
        label: "Сумма",
        type: TYPES.DECIMAL,
        interval: {min: 0},
        additional: true,
    },
    {
        name: "exhibited",
        label: "Выставлено",
        type: TYPES.DECIMAL,
        interval: {min: 0},
        additional: true,
    },
    {
        name: "paid",
        label: "Оплачено",
        type: TYPES.DECIMAL,
        interval: {min: 0},
        additional: true,
    },
    {
        name: "contractor",
        label: "Заказчик",
        type: TYPES.SELECT,
        entityName: ENTITIES.CUSTOMER,
        additional: true,
    },
    {
        name: "executor",
        label: "Исполнитель",
        type: TYPES.SELECT,
        entityName: ENTITIES.EMPLOYEE,
        additional: true,
    },
    {
        name: "owner",
        label: "Владелец",
        type: TYPES.SELECT,
        entityName: ENTITIES.EMPLOYEE,
        additional: true,
    },
    {
        name: "author",
        label: "Автор",
        type: TYPES.SELECT,
        entityName: ENTITIES.EMPLOYEE,
        initialValue: (mode, user) => user.employeeId,
        additional: true,
    },
];


export const tabs = [
    {
        name: 'details',
        title: 'Детали',
        component: <DetailsView/>
    },
    {
        name: 'tasks',
        title: 'Задачи',
        component: <TaskView/>
    },
    {
        name: 'executionPlans',
        title: 'План выполнения',
        component: <ExecutionPlanView/>
    },
    {
        name: 'executionFacts',
        title: 'Акт вып. работ',
        component: <ExecutionFactView/>
    },
    {
        name: 'paymentPlans',
        title: 'Платежи - План',
        component: <PaymentPlanView/>
    },
    {
        name: 'paymentFacts',
        title: 'Платежи - Факт',
        component: <PaymentFactView/>
    },
];
