import * as React from 'react';
import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import {removeUser, selectUser} from "../../store/UserSlice";
import {selectNavigationDrawerIsOpen, setNavigationDrawerToggle, setSize} from "../../store/DisplaySlice";
import {DRAWER_WIDTH, MAIN_HEIGHT_OFFSET} from "../../utils/constants";
import MainBar from "./MainBar/MainBar";
import MenuDrawer from "./MenuDrawer/MenuDrawer";
import {SocketContext} from "../../context/socket";


const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(2),
        overflow: 'hidden',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${DRAWER_WIDTH}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

export default function MainDrawer({children}) {

    const user = useSelector(selectUser);
    const navigationDrawerIsOpen = useSelector(selectNavigationDrawerIsOpen);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext)

    useEffect(() => {
        dispatch(setSize(window.innerHeight - MAIN_HEIGHT_OFFSET,
            navigationDrawerIsOpen ? window.innerWidth - DRAWER_WIDTH : window.innerWidth))
    }, [dispatch, navigationDrawerIsOpen]);

    useEffect(() => {
        const handleResize = (event) => {
            dispatch(setSize(event.target.innerHeight - MAIN_HEIGHT_OFFSET,
                navigationDrawerIsOpen ? event.target.innerWidth - DRAWER_WIDTH : event.target.innerWidth))
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch, navigationDrawerIsOpen]);

    const handleLogout = async () => {
        localStorage.removeItem('token');
        socket.emit("logout", socket.id)
        dispatch(removeUser());
    };
    const handleDrawerOpen = () => dispatch(setNavigationDrawerToggle())
    const handleDrawerClose = () => dispatch(setNavigationDrawerToggle())
    const handleClickItemMenu = (item) => navigate(item.link)

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <MainBar drawerOpen={handleDrawerOpen} onLogout={handleLogout} open={navigationDrawerIsOpen} user={user}/>
            <MenuDrawer onClose={handleDrawerClose} open={navigationDrawerIsOpen} onClickItemMenu={handleClickItemMenu}
                        user={user}/>
            <Main open={navigationDrawerIsOpen}>
                {children}
            </Main>
        </Box>
    )
        ;
}
