import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


export const addButton = {
  id: 'add',
  label: 'Добавить',
  icon: <AddIcon/>,
};

export const editButton = {
  id: 'edit',
  label: 'Изменить',
  icon: <EditIcon/>,
  disabled: (selection) => {
    return !selection;
  },
};

export const deleteButton = {
  id: 'delete',
  label: 'Удалить',
  icon: <DeleteIcon/>,
  disabled: (selection) => {
    return !selection;
  },
};
