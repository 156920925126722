import * as React from 'react';
import {Popover, Switch} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";


export default function ColumnSelector(props) {

    const {
        onClick,
        onClose,
        open,
        items,
        anchorEl
    } = props;

    return (<Popover
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorEl={anchorEl}
            disableRestoreFocus
            onClose={onClose}
            open={open}
        >
            <List>
                {items.map((item) => {
                    return (
                        <ListItem
                            key={item.id}
                            onClick={() => onClick(item)}
                        >
                            <Switch
                                checked={item.visible}
                                color="primary"
                                value={item.label}
                                size="small"
                            />
                            <ListItemText primary={item.label}/>
                        </ListItem>
                    );
                })}
            </List>
        </Popover>
    );
}

