import React from 'react';
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import {selectDialogData} from "../../store/DialogSlice";
import {renderField} from "../../containers/Dialog/DataDialog";
import {DIALOG_GRID_HEIGHT} from "../../utils/constants";


const offset = 94

const DetailsView = ({fields, dialogName}) => {
    const dialog = useSelector(selectDialogData(dialogName));
    return (
        <Grid sx={{height: DIALOG_GRID_HEIGHT + offset, overflowY: "auto"}}>
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 1, md: 1, xl: 2}}>
                {fields.filter(field => field.additional).reduce((layout, field) => renderField(layout, field, dialog.mode, dialogName), [])}
            </Grid>
        </Grid>
    );
};

export default DetailsView;
