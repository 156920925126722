import {settings, SETTINGS_CONFIG_NAME} from "./constants";

export function classnames(cls, mods, additional) {
    return [
        cls,
        ...additional,
        ...Object.entries(mods).filter(([className, value]) => Boolean(value)).map(([className]) => className)
    ].join(" ")
}

export const checkTableFeature = (featureName, featuresFromConfig) => {
    if (featuresFromConfig == null) return false;
    return featuresFromConfig.find((item) => item.name === featureName);
};

export const getColumnOrder = (columns) => columns.map(column => column.id)

export const getColumnWidths = (columns) => {
    return columns.map(column => {
        return {name: column.id, width: column.minWidth || 100}
    })
}


export function updateTableSettingLocalStorage(tableKey, settingName, value) {
    const localStorageConfig = JSON.parse(localStorage.getItem(SETTINGS_CONFIG_NAME))
    const tableSetting = localStorageConfig[tableKey][settingName]
    tableSetting.value = value
    localStorage.setItem(SETTINGS_CONFIG_NAME, JSON.stringify(localStorageConfig))
}

export const needToUpdateSettingsConfig = ({
                                               accessKey, currentConfig, localStorageConfig,
                                           }) => {
    if (!localStorageConfig || !localStorageConfig[accessKey]) return true;

    const localStorageColumnWidth = localStorageConfig[accessKey].resizing.value.slice();
    const localStorageColumnOrder = localStorageConfig[accessKey].reordering.value.slice();

    const currentColumnWidth = currentConfig[settings.RESIZING].value.slice();
    const currentColumnOrder = currentConfig[settings.REORDERING].value.slice();

    const isColumnWidthEquals = compareTableFeature(localStorageColumnWidth, currentColumnWidth);
    const isColumnOrderEquals = compareColumnOrdering(localStorageColumnOrder, currentColumnOrder);

    if (!isColumnWidthEquals || !isColumnOrderEquals) return true;

    // config key amount
    const configKeyAmount = getAllKeysAsArray(currentConfig).length;
    const localStorageConfigKeyAmount = getAllKeysAsArray(localStorageConfig[accessKey]).length;

    return configKeyAmount > localStorageConfigKeyAmount;
};

function compareTableFeature(a, b) {
    if (!Array.isArray(a) || !Array.isArray(b)) return false;
    if (a.length !== b.length) return false;
    for (let i = 0, length = a.length; i < length; i += 1) {
        if (b[i] == null) return null;
        if (a.name !== b.name) return false;
    }
    return true;
}

function compareColumnOrdering(a, b) {
    if (!Array.isArray(a) || !Array.isArray(b)) return false;
    if (a.length !== b.length) return false;
    const arrayA = a.slice().sort();
    const arrayB = b.slice().sort();
    for (let i = 0, length = arrayA.length; i < length; i += 1) {
        if (arrayA[i] !== arrayB[i]) return false;
    }
    return true;
}

export const getAllKeysAsArray = (object) => {
    const result = [];
    const getAllKeysOfTheObject = (obj) => {
        if (!isObject(obj) && !isArray(obj)) return null;
        if (isObject(obj)) {
            const keys = Object.keys(obj);
            keys.map((item) => { // eslint-disable-line
                if (item !== 'undefined') result.push(item);
            });
            for (let i = 0; i < keys.length; i += 1) {
                getAllKeysOfTheObject(obj[keys[i]]);
            }
        }
        if (isArray(obj) && (obj.length > 0)) {
            for (let i = 0; i < obj.length; i += 1) {
                getAllKeysOfTheObject(obj[i]);
            }
        }
        return null;
    };

    getAllKeysOfTheObject(object);
    return result;
};


const ARRAY_TYPE = 'Array';
const BOOLEAN_TYPE = 'Boolean';
const DATE_TYPE = 'Date';
const FUNCTION_TYPE = 'Function';
const NULL_TYPE = 'Null';
const NUMBER_TYPE = 'Number';
const OBJECT_TYPE = 'Object';
const REG_EXP_TYPE = 'RegExp';
const STRING_TYPE = 'String';
const UNDEFINED_TYPE = 'Undefined';

export const getTypeInfo = (object) => (Object.prototype.toString.call(object).slice(8, -1));

export const isArray = (object) => (getTypeInfo(object) === ARRAY_TYPE);
export const isBoolean = (object) => (getTypeInfo(object) === BOOLEAN_TYPE);
export const isDate = (object) => (getTypeInfo(object) === DATE_TYPE);
export const isFunction = (object) => (getTypeInfo(object) === FUNCTION_TYPE);
export const isNull = (object) => (getTypeInfo(object) === NULL_TYPE);
export const isNumber = (object) => (getTypeInfo(object) === NUMBER_TYPE);
export const isObject = (object) => (getTypeInfo(object) === OBJECT_TYPE);
export const isRegExg = (object) => (getTypeInfo(object) === REG_EXP_TYPE);
export const isString = (object) => (getTypeInfo(object) === STRING_TYPE);
export const isUndefined = (object) => (getTypeInfo(object) === UNDEFINED_TYPE);
