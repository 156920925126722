import * as React from 'react';
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import IconButton from "@mui/material/IconButton";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import {setOpenDialog} from "../../store/DialogSlice";
import {setOpenConfirmDialog} from "../../store/ModalSlice";
import {logoutUser} from "../../http/userAPI";
import './styles.css'


export default function ActionMenu({items, row, entity, params}) {

    const dispatch = useDispatch();
    const [calcItems, setCalcItems] = useState([]);

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    // расчет видимости кнопок
    useEffect(() => {
        const _items = items && items.map(b => {
            const hidden = typeof b.hidden === 'function' ? b.hidden(row) : b.hidden;
            return {...b, hidden}
        });
        setCalcItems(_items);
    }, [items, row]);


    const handleClick = (e) => {
        e.stopPropagation();
        setOpen(true)
        setAnchorEl(e.currentTarget);
    }

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false)
    }

    function handleItemClick(e, item) {
        e.stopPropagation()
        setOpen(false)

        const mode = item.id
        const dialogName = item.dialogName

        if (mode === 'edit') {
            dispatch(setOpenDialog(dialogName, {isOpen: true, mode, values: {}, id: row.id}))
        } else if (mode === 'delete') {
            dispatch(setOpenConfirmDialog({id: row.id, entity, params}))
        } else if (mode === 'logOut') {
            logoutUser(row.id).then()
        }
    }

    return (
        <td>
            <IconButton
                className={"toolbar"}
                onClick={handleClick}
            >
                <MenuOutlinedIcon/>
            </IconButton>

            <Popover
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                disableRestoreFocus
                onClose={handleClose}
                open={open}
            >
                <List>
                    {calcItems.map((item) => {
                        if (item.hidden) return null;
                        return (<ListItem
                                key={item.id}
                                className={"menuItem"}
                                onClick={(e) => handleItemClick(e, item)}
                            >
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.label}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Popover>
        </td>
    )
}

