import React, {useEffect} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {DialogContentText} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {selectConfirmDialog, setCloseConfirmDialog} from "../../store/ModalSlice";
import {useDispatch, useSelector} from "react-redux";
import {deleteData, loadData} from "../../http/dataAPI";
import {setEntity} from "../../store/DataSlice";
import {logoutUser} from "../../http/userAPI";

const ConfirmDeleteDialog = () => {

    const dialog = useSelector(selectConfirmDialog)
    const dispatch = useDispatch()

    const handleClose = () => dispatch(setCloseConfirmDialog())

    const handleConfirm = () => {
        const {entity, params, id} = dialog
        if (entity === 'user') {
            logoutUser(id).then()
        }
        deleteData(entity, id)
            .then(() => {
                loadData(entity, params).then(data => dispatch(setEntity({name: entity, data})));
            });
        dispatch(setCloseConfirmDialog());
    }


    return (
        <Dialog
            open={dialog.open || false}
            onClose={handleClose}
        >
            <DialogTitle>
                Внимание
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Подтвердите удаление
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}> OK </Button>
                <Button onClick={handleClose} autoFocus> Отмена </Button>
            </DialogActions>
        </Dialog>

    );
};

export default ConfirmDeleteDialog;