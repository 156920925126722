import React from 'react';
import {tableButtons, columns, sorting, getTableFeatures, tableMenuItems} from './templates/table'
import {fields} from './templates/dialog'
import ViewContainer from "../../containers/ViewContainer";
import {ENTITIES} from "../../utils/constants";


const PaymentFactView = (props) => {
    return (
        <ViewContainer
            viewName={ENTITIES.PAYMENT_FACT}
            buttons={tableButtons(`${ENTITIES.PAYMENT_FACT}Dialog`)}
            menuItems={tableMenuItems(`${ENTITIES.PAYMENT_FACT}Dialog`)}
            fields={fields}
            addTitle={"Добавить фактический платеж"}
            editTitle={"Изменить фактический платеж"}
            columns={columns}
            sorting={sorting}
            tableFeatures={getTableFeatures()}
            maxWidth="md"
            {...props}
        />
    );
};

export default PaymentFactView;
