import React from 'react';
import {tableButtons, columns, sorting, getTableFeatures, tableMenuItems} from './templates/table'
import {fields} from './templates/dialog'
import ViewContainer from "../../containers/ViewContainer";
import {ENTITIES} from "../../utils/constants";


const ExecutionPlanView = (props) => {
    return (
        <ViewContainer
            viewName={ENTITIES.EXECUTION_PLAN}
            buttons={tableButtons(`${ENTITIES.EXECUTION_PLAN}Dialog`)}
            menuItems={tableMenuItems(`${ENTITIES.EXECUTION_PLAN}Dialog`)}
            fields={fields}
            addTitle={"Добавить план выполнения"}
            editTitle={"Изменить план выполнения"}
            columns={columns}
            sorting={sorting}
            tableFeatures={getTableFeatures()}
            maxWidth="md"
            {...props}
        />
    );
};

export default ExecutionPlanView;
