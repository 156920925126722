import React from 'react';
import DataDialog from "./DataDialog";

const AdvancedDialog = (props) => {
  return (
    <DataDialog {...props}/>
  );
};

export default AdvancedDialog;
