import {configureStore} from "@reduxjs/toolkit";
import dataReducer from './DataSlice'
import userReducer from './UserSlice'
import dialogReducer from './DialogSlice'
import displayReducer from './DisplaySlice'
import mainBarReducer from './MainBarSlice'
import modalReducer from './ModalSlice'
import notificationAlertReducer from './NotificationAlertSlice'

export default configureStore({
    reducer: {
        data: dataReducer,
        user: userReducer,
        dialog: dialogReducer,
        display: displayReducer,
        mainBar: mainBarReducer,
        modal: modalReducer,
        notificationAlert: notificationAlertReducer,
    }
})
