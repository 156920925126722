import {createSlice} from '@reduxjs/toolkit'

const notificationAlertSlice = createSlice({
    name: 'notificationAlert',
    initialState: {
        content: null,
        heading: null,
        typeId: null,
    },

    reducers: {
        setNotification(state, action) {
            state.content = action.payload.content
            state.heading = action.payload.heading
            state.typeId = action.payload.typeId
        },
        clearNotification(state, action) {
            state.content = null
            state.heading = null
            state.typeId = null
        }
    }
});

export const selectNotificationAlert = (state) => state && state['notificationAlert']

export const {setNotification, clearNotification} = notificationAlertSlice.actions;

export default notificationAlertSlice.reducer;
