import {ENTITIES, MODES, ROLES, TYPES} from "../../../utils/constants";

export const fields = [
    {
        name: "login",
        label: "Логин",
        grid: {xs: 12, sm: 12}
    },
    {
        name: "password",
        label: "Пароль",
        // visible: (mode) => mode === MODES.ADD,
        grid: {xs: 12, sm: 12},
    },
    {
        name: "role",
        label: "Роль",
        type: TYPES.SELECT,
        entityName: ENTITIES.ROLE,
        initialValue: ROLES.USER,
        grid: {xs: 12, sm: 12},
    },
    {
        name: "employee",
        label: "Сотрудник",
        type: TYPES.SELECT,
        entityName: ENTITIES.EMPLOYEE,
        grid: {xs: 12, sm: 12},
    },
    {
        name: "autoUpdate",
        label: "Автообновление",
        type: TYPES.BOOLEAN,
        grid: {xs: 12, sm: 12},
    },
];
