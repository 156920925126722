import * as React from "react";
import ArticleIcon from '@mui/icons-material/Article';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import GroupIcon from '@mui/icons-material/Group';


export const getIconByName = (id) => {
    switch (id) {
        case 'agreements':
            return (<ArticleIcon/>);
        case 'customers':
            return <ContactPageIcon/>;
        case 'employees':
            return (<AccountBoxIcon/>);
        case 'users':
            return (<PersonIcon/>);
        case 'tasks':
            return (<ArticleIcon/>);
        case 'notifications':
            return (<NotificationsNoneOutlinedIcon/>);
        case 'add':
            return (<AddIcon/>);
        case 'edit':
            return (<EditIcon/>);
        case 'chat':
            return (<GroupIcon/>)

        default:
            return null;
    }
};
