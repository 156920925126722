import React from "react";
import AuthDialog from '../../pages/Login/AuthDialog'
import Agreements from '../../pages/Agreements'
import Customers from '../.././pages/Customers'
import Employees from '../.././pages/Employees'
import Tasks from '../.././pages/Tasks'
import Users from '../.././pages/Users'
import Notifications from '../.././pages/Notifications'
import Chat from '../.././pages/Chat'

import {
    AGREEMENTS_ROUTE, CHAT_ROUTE, CUSTOMERS_ROUTE,
    EMPLOYEES_ROUTE, LOGIN_ROUTE, NOTIFICATIONS_ROUTE,
    TASKS_ROUTE, USERS_ROUTE
} from "../../utils/constants";


export const authRoutes = [
    {
        path: AGREEMENTS_ROUTE,
        element: <Agreements/>
    },
    {
        path: CUSTOMERS_ROUTE,
        element: <Customers/>
    },
    {
        path: EMPLOYEES_ROUTE,
        element: <Employees/>
    },
    {
        path: TASKS_ROUTE,
        element: <Tasks/>
    },
    {
        path: USERS_ROUTE,
        element: <Users/>
    },
    {
        path: NOTIFICATIONS_ROUTE,
        element: <Notifications/>
    },
    {
        path: CHAT_ROUTE,
        element: <Chat/>
    },
];

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        element: <AuthDialog/>
    },
];
