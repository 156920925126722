import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField/TextField";
import {selectDialogDataValueByName, setFieldValue} from "../../store/DialogSlice";
import {getSuggestions} from "../../http/dataAPI";


const DadataField = ({name, label, dialogName}) => {

    const dispatch = useDispatch();
    const initValue = useSelector(selectDialogDataValueByName(dialogName, name))
    const [options, setOptions] = useState([])
    const [value, setValue] = useState(null)


    useEffect(() => {
        if (initValue) {
            setValue(initValue)
        }
    }, [initValue])

    const handleTextFieldChange = (e) => {
        getSuggestions(e.target.value).then(result => {
            const newOptions = result.data.suggestions.map(item => item.value)
            setOptions(newOptions)
        })
    }

    const handleAutocompleteChange = (event, value, reason) => {
        if (reason === "clear") {
            setOptions([])
            setValue(null)
            dispatch(setFieldValue(dialogName, name, null))
        } else if (reason === "selectOption") {
            dispatch(setFieldValue(dialogName, name, value))
            setValue(value)
        }
    };

    return (
        <Autocomplete
            id={name}
            fullWidth
            freeSolo
            value={value}
            onChange={handleAutocompleteChange}
            options={options}
            renderInput={
                (params) => <TextField {...params} label={label} onChange={handleTextFieldChange}/>
            }
        />
    );
};

export default DadataField;
