import {addButton, deleteButton, editButton} from "../../common/buttons";
import {COLUMN_WIDTHS, settings, SORT, TYPES} from "../../../utils/constants";
import {deleteActionItem, editActionItem} from "../../common/menuItems";

export const columns = [
    {
        id: 'number',
        label: 'Номер',
        type: TYPES.DECIMAL,
        minWidth: COLUMN_WIDTHS.SMALL
    },
    {
        id: 'status',
        path: 'status.name',
        label: 'Статус',
        type: TYPES.LINK,
        entityName: 'status',
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'contractor',
        path: 'contractor.name',
        label: 'Заказчик',
        type: TYPES.LINK,
        entityName: 'customer',
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'customer',
        path: 'customer.name',
        label: 'Контрагент',
        type: TYPES.LINK,
        entityName: 'customer',
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'signingDate',
        label: 'Дата подписания',
        type: TYPES.DATE,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'type',
        path: 'type.name',
        label: 'Вид',
        type: TYPES.LINK,
        entityName: 'type',
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'amount',
        label: 'Сумма',
        type: TYPES.DECIMAL,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'exhibited',
        label: 'Выставлено',
        type: TYPES.DECIMAL,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'paid',
        label: 'Оплачено',
        type: TYPES.DECIMAL,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'executor',
        path: 'executor.name',
        label: 'Исполнитель',
        type: TYPES.LINK,
        entityName: 'employee',
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'owner',
        path: 'owner.name',
        label: 'Владелец',
        type: TYPES.LINK,
        entityName: 'employee',
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'author',
        path: 'author.name',
        label: 'Автор',
        type: TYPES.LINK,
        entityName: 'employee',
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'file',
        label: 'Файл',
        type: TYPES.FILE,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'scan',
        label: 'Скан',
        type: TYPES.FILE,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },

];

export const tableButtons = (dialogName) => [
    {...addButton, dialogName}, {...editButton, dialogName}, {...deleteButton, dialogName}
];

export const tableMenuItems = (dialogName) => [
    {...editActionItem, dialogName}, {...deleteActionItem, dialogName}
]

export const sorting = {column: 'number', direction: SORT.DESC}

export function getTableFeatures() {
    return [
        {name: settings.REORDERING, value: true},
        {name: settings.RESIZING, value: true},
        {name: settings.HIDDING, value: true},
        {name: settings.FULL_TEXT_SEARCH, value: true},
        {name: settings.COLUMN_SEARCH, value: true},
    ];
}