import {createSlice} from '@reduxjs/toolkit'

const dataSlice = createSlice({
    name: 'data',
    initialState: {
        entities: {
            file: [],
        },
        total: {},
        tables: {}
    },

    reducers: {

        setEntity(state, action) {
            state.entities[action.payload.name] = action.payload.data.rows ? action.payload.data.rows : action.payload.data
            state.total[action.payload.name] = action.payload.data.count && action.payload.data.count
        },

        setEntityWithoutTotal(state, action) {
            state.entities[action.payload.name] = action.payload.data.rows ? action.payload.data.rows : action.payload.data
        },

        setTotalEntity(state, action) {
            state.total[action.payload.name] = action.payload.data
        },

        incTotalEntity(state, action) {
            state.total[action.payload] = state.total[action.payload] + 1
        },

        decTotalEntity(state, action) {
            state.total[action.payload] = state.total[action.payload] - 1
        },

        clearEntity(state, action) {
            state.entities[action.payload.name] = []
            state.total[action.payload.name] = 0
        },

        setRowSelected: {
            reducer(state, action) {
                state.tables[action.meta] = {...state.tables[action.meta], ...action.payload}
            },
            prepare(name, id) {
                return {
                    meta: name,
                    payload: {
                        selection: id
                    }
                }
            }
        },

        setParentParams: {
            reducer(state, action) {
                state.tables[action.meta] = {
                    ...state.tables[action.meta],
                    params: {
                        ...state.tables[action.meta].params,
                        parent: action.payload,
                    }
                }

            },
            prepare(name, params) {
                return {
                    meta: `${params.name}-${name}`,
                    payload: {id: params.name + "Id", operation: "eq", value: params.id}
                }
            }
        },


        setParams: {
            reducer(state, action) {
                state.tables[action.meta] = {...state.tables[action.meta], params: action.payload}
            },
            prepare(name, params) {
                return {
                    meta: name,
                    payload: params
                }
            }
        },

        setInitUrlParamsDataTable: {
            reducer(state, action) {
                state.tables[action.meta] = {
                    ...state.tables[action.meta],
                    params: action.payload,
                    pageSizes: [5, 10, 15, 20]
                }
            },
            prepare(name, params) {
                return {
                    meta: name,
                    payload: params
                }
            }
        },

        setParameterDataTable: {
            reducer(state, action) {
                state.tables[action.meta] = {
                    ...state.tables[action.meta],
                    ...action.payload
                }
            },
            prepare(name, parameter) {
                return {
                    meta: name,
                    payload: parameter
                }
            }
        },

        setLoaderStateDataTable: {
            reducer(state, action) {
                state.tables[action.meta] = {...state.tables[action.meta], ...action.payload}
            },
            prepare(name, status) {
                return {
                    meta: name,
                    payload: {
                        isLoading: status
                    }
                }
            }
        },


        addEntity(state, action) {
            state.entities[action.payload.name] = [...state.entities[action.payload.name], action.payload.data]
        },

        removeEntity(state, action) {
            state.entities[action.payload.name] = state.entities[action.payload.name].filter(i => i.id !== action.payload.id)
        }
    }
});


export const selectEntities = () => (state) => state.data.entities;

export const selectEntityData = (entityName) => (state) => state.data.entities[entityName];

export const selectEntityDataById = (entityName, id) => (state) => {
    return state.data.entities[entityName]?.filter(r => r.id === id)[0]
};

export const selectSelectedRowId = (tableName) => (state) => state.data.tables[tableName]?.selection || null;

export const selectParams = (tableName) => (state) => {
    return state.data.tables[tableName]?.params || null
}
export const selectPageSizes = (tableName) => (state) => {
    return state.data.tables[tableName]?.pageSizes
}

export const selectColumnOrder = (tableName) => (state) => {
    return state.data.tables[tableName]?.columnOrder
}

export const selectColumnWidths = (tableName) => (state) => {
    return state.data.tables[tableName]?.columnWidths
}

export const selectColumnHidden = (tableName) => (state) => {
    return state.data.tables[tableName]?.columnHidden
}

export const selectColumnSearch = (tableName) => (state) => {
    return state.data.tables[tableName]?.columnSearch
}

export const selectTotalCount = (entity) => (state) => {
    return state.data.total[entity]
}


export const selectLoaderState = (tableName) => (state) => state.data.tables[tableName]?.isLoading || false


export const {
    setEntity,
    setEntityWithoutTotal,
    setTotalEntity,
    incTotalEntity,
    decTotalEntity,
    clearEntity,
    setRowSelected,
    addEntity,
    removeEntity,
    setParentParams,
    setParams,
    setInitUrlParamsDataTable,
    setParameterDataTable,
    setLoaderStateDataTable,
} = dataSlice.actions;

export default dataSlice.reducer;

