import {createSlice} from '@reduxjs/toolkit'

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        alert: {
            open: false,
            content: '',
        },
        confirm: {
            open: false,
        }
    },

    reducers: {
        setOpenAlertDialog(state, action) {
            state['alert'].open = true
            state['alert'].content = action.payload.content
        },
        setCloseAlertDialog(state) {
            state['alert'].open = false
        },
        setOpenConfirmDialog(state, action) {
            state['confirm'] = {...action.payload}
            state['confirm'].open = true
        },
        setCloseConfirmDialog(state) {
            state['confirm'].open = false
            state['confirm'].confirm = false
        },
    }
});


export const selectAlertDialog = (state) => state && state.modal['alert']

export const selectConfirmDialog = (state) => state && state.modal['confirm']

export const {
    setOpenAlertDialog,
    setCloseAlertDialog,
    setOpenConfirmDialog,
    setCloseConfirmDialog,
} = modalSlice.actions;

export default modalSlice.reducer;
