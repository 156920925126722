import {loadData, loadDataById} from "./dataAPI";
import {TYPES} from "../utils/constants";
import {addEntity, setEntity, setLoaderStateDataTable} from "../store/DataSlice";

export async function fetchEntityData(entity, params) {
    return await loadData(entity, params)
}

export async function loadSelectFieldsEntities(fields, dispatch) {
    const uniqueFields = fields
        .filter(c => (c.type === TYPES.SELECT || c.type === TYPES.LINK) && c.entityName)
        .map(c => c.entityName)
        .reduce((acc, item) => {
            if (acc.includes(item)) {
                return acc;
            }
            return [...acc, item];
        }, []);
    uniqueFields.forEach(entity => {
        fetchEntityData(entity).then(data => dispatch(setEntity({name: entity, data})));
    });
}

export async function loadDataEntity(dispatch, name, entity, params, accessKey) {
    fetchEntityData(entity, params).then(data => {
        // статус загрузки данных true
        dispatch(setLoaderStateDataTable(accessKey, true))
        dispatch(setEntity({name: entity, data}))
    });
}


export async function fetchOneRecordById(entity, id) {
    return await loadDataById(entity, id)
}

export async function loadOneRecordById(dispatch, entity, id) {
    fetchOneRecordById(entity, id).then(data => dispatch(addEntity({name: entity, data})));
}

