import jwtDecode from "jwt-decode";
import {fetchEventSource} from '@microsoft/fetch-event-source';
import {$host, $authHost, baseURL} from "./index";
import {removeUser} from "../store/UserSlice";
import {incTotalEntity, setLoaderStateDataTable} from "../store/DataSlice";
import {setNotification} from "../store/NotificationAlertSlice";
import {ENTITIES} from "../utils/constants";


export const registration = async (login, password) => {
    const {data} = await $host.post('api/user/registration', {login, password});
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token)
};

export const login = async (login, password) => {
    const {data} = await $host.post('api/user/login', {login, password});
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token)
};

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth');
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token)
};


// long polling logout command
// export const logoutUser = async (id) => {
//     return await $authHost.post(`/lpe/new-event`, {command: 'logout', id}).then()
// }

// long polling subscribe
// export const subscribe = async (user, dispatch) => {
//     try {
//         const {data} = await $authHost.get('/lpe/get-event')
//         const currentView = JSON.parse(localStorage.getItem('currentView'))
//         //обновление грида
//         if (user.autoUpdate && data.command === 'update-view' && data.userId !== user.id && data.name === currentView.viewName) {
//             dispatch(setLoaderStateDataTable(`${data.name}Table`, false))
//         }
//         // выход из системы
//         else if (data.command === 'logout' && data.id === user.id) {
//             localStorage.removeItem('token');
//             dispatch(removeUser());
//             window.location.reload(true);
//         }
//         await subscribe(user, dispatch)
//     } catch (e) {
//         setTimeout(() => {
//             subscribe(user, dispatch)
//         }, 500)
//     }
// }

export const logoutUser = async (id) => {
    return await $authHost.post(`${baseURL}/sse/logout-user`, {id}).then()
}

export const subscribeSSE = async (user, dispatch) => {
    await fetchEventSource(`${baseURL}/sse/connect`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
        onmessage(event) {
            const data = JSON.parse(event.data)
            if (data.type !== 5) {
                // оповещения
                dispatch(setNotification(data))
                dispatch(incTotalEntity(ENTITIES.NOTIFICATION))
            } else {
                // сигнал выхода для пользователя
                if (user.id === data.id) {
                    localStorage.removeItem('token');
                    window.location.reload(true);
                }
            }
        }
    });
}


// Не поддерживает авторизацию
// export const subscribeSSE = (user, dispatch) => {
//     const eventSource = new EventSource(`${baseURL}/sse-connect?id=${user.id}`, {})
//     eventSource.onmessage = (event) => {
//         const data = JSON.parse(event.data)
//
//         if (data.type !== 5) {
//             // оповещения
//             dispatch(setNotification(data))
//             dispatch(incTotalEntity(ENTITIES.NOTIFICATION))
//         } else {
//             // сигнал выхода для пользователя
//             if (user.id === data.id) {
//                 localStorage.removeItem('token');
//                 window.location.reload(true);
//             }
//         }
//     }
// }
