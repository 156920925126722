import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import Button from '@mui/material/Button';
import {VisibilityOff} from "@mui/icons-material";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

import Search from "../../components/Search";
import ColumnSelector from "../../components/ColumnSelector";
import ToolbarButton from "../../components/ToolButton";
import FilterListDialog from "./FilterListDialog";

import {setOpenDialog} from "../../store/DialogSlice";
import {selectSelectedRowId, setParameterDataTable, setParams} from "../../store/DataSlice";
import {updateTableSettingLocalStorage} from "../../utils/helpers";
import {getFilterMessages, settings, SETTINGS_CONFIG_NAME} from "../../utils/constants";
import {loadDataEntity} from "../../http/helper";
import {setOpenConfirmDialog} from "../../store/ModalSlice";
import './DataTableToolBar.css'


export default function DataTableToolBar({
                                             name,
                                             buttons,
                                             params,
                                             entity,
                                             columns,
                                             columnOrder,
                                             columnHidden,
                                             columnSearch,
                                             accessKey,
                                         }) {

    const selectionRowId = useSelector(selectSelectedRowId(name));
    const dispatch = useDispatch();
    const [calcButtons, setCalcButtons] = useState([]);
    const [columnSelectorIsOpen, setColumnSelectorIsOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenFilterDialog, setIsOpenFilterDialog] = useState(false)
    const [searchValue, setSearchValue] = useState('')


// расчет видимости кнопок
    useEffect(() => {
        const _buttons = buttons && buttons.map(b => {
            const disabled = typeof b.disabled === 'function' ? b.disabled(selectionRowId) : b.disabled;
            return {...b, disabled}
        });
        setCalcButtons(_buttons);
    }, [buttons, selectionRowId]);


    // ----Full Text Search------------------------
    function handleFullTextSearchChangeValue(event) {
        setSearchValue(event.currentTarget.value)
    }

    function handleFullTextSearch() {
        const newParams = {...params, search: searchValue === '' ? null : searchValue}
        dispatch(setParams(name, newParams))
        loadDataEntity(dispatch, name, entity, newParams).then();
    }

    //-----------------------------------------------

    function handleClick(mode, dialogName) {
        if (mode === 'add') {
            dispatch(setOpenDialog(dialogName, {isOpen: true, mode, values: {}}))
        } else if (mode === 'edit') {
            dispatch(setOpenDialog(dialogName, {isOpen: true, mode, values: {}, id: selectionRowId}))
        } else if (mode === 'delete') {
            dispatch(setOpenConfirmDialog({id: selectionRowId, entity, params}))
        }
    }

    // --------Column Selector----------------
    const handleClickColumnSelectorButton = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setColumnSelectorIsOpen(true)
    };

    const handleCloseColumnSelector = () => setColumnSelectorIsOpen(false);

    const getColumnSelectorItems = () => {
        const columnSelectorItems = []
        columnOrder && columnOrder.forEach(o => {
            const column = columns.find(col => col.id === o)
            if (column) {
                columnSelectorItems.push({
                    id: column.id,
                    label: column.label,
                    visible: !columnHidden.includes(column.id)
                })
            }
        });
        return columnSelectorItems
    }

    const handleColumnSelectorClick = (column) => {
        const newColumnHidden = columnHidden.includes(column.id)
            ? columnHidden.filter(c => c !== column.id)
            : [...columnHidden, column.id]
        dispatch(setParameterDataTable(accessKey, {columnHidden: newColumnHidden}))
        updateTableSettingLocalStorage(accessKey, settings.HIDDING, newColumnHidden)
    };

    const handleClickFilterButton = () => setIsOpenFilterDialog(true)

    const handleClickFilterCancel = () => setIsOpenFilterDialog(false)

    const handleClickFilterAccept = (columnSearch) => {
        setIsOpenFilterDialog(false)
        const newParams = {...params, filterBy: columnSearch}
        dispatch(setParams(name, newParams))
        loadDataEntity(dispatch, name, entity, newParams).then();
        updateFilterInLocalStorage(name, columnSearch)
    };
    const handleClickFilterOffButton = () => {
        setSearchValue('')
        const newParams = {...params, filterBy: null, search: null}
        dispatch(setParams(name, newParams))
        dispatch(setParameterDataTable(name, {columnSearch: []}))
        loadDataEntity(dispatch, name, entity, newParams).then();
        updateFilterInLocalStorage(name, [])
    }

    const checkDisableFilterOffButton = () => {
        return !(params?.filterBy?.length > 0 || params?.search)
    }

    const updateFilterInLocalStorage = (accessKey, filters) => {
        const localStorageConfig = JSON.parse(localStorage.getItem(SETTINGS_CONFIG_NAME))
        localStorageConfig[accessKey][settings.COLUMN_SEARCH].value = filters;
        localStorage.setItem(SETTINGS_CONFIG_NAME, JSON.stringify(localStorageConfig))
    }

    return (
        <div className={"toolbar-container"}>
            <div className={"buttons-container"}>
                {calcButtons && calcButtons.map(button => (
                    <div className={"button"} key={button.id}>
                        <Button component="button"
                                variant="contained"
                                startIcon={button.icon}
                                disabled={button.disabled}
                                onClick={() => handleClick(button.id, button.dialogName)}>
                            {button.label}
                        </Button>
                    </div>
                ))}
            </div>
            <div className={"tool-buttons-container"}>
                <div className={"search-container"}>
                    <Search
                        value={searchValue}
                        onChange={handleFullTextSearchChangeValue}
                        onSearch={handleFullTextSearch}
                    />
                </div>
                <div style={{display: "flex"}}>
                    <ToolbarButton
                        onClick={handleClickFilterButton}
                        tooltipTitle={'Фильтр'}
                        name="filters"
                        icon={<FilterListIcon/>}
                    />
                    <ToolbarButton
                        onClick={handleClickFilterOffButton}
                        tooltipTitle={'Очистить'}
                        name="filtersOff"
                        icon={<FilterListOffIcon/>}
                        disabled={checkDisableFilterOffButton()}
                    />

                    {
                        isOpenFilterDialog &&
                        <FilterListDialog
                            name={name}
                            onCancel={handleClickFilterCancel}
                            onSubmit={handleClickFilterAccept}
                            messages={getFilterMessages}
                            accessKey={name}
                            columns={columns}
                            columnOrder={columnOrder}
                            columnHidden={columnHidden}
                            columnSearch={columnSearch}
                        />
                    }

                </div>
                <div>
                    <ToolbarButton
                        onClick={(event) => handleClickColumnSelectorButton(event)}
                        tooltipTitle={'Настроить видимость колонок'}
                        name="columnSelector"
                        icon={<VisibilityOff/>}
                    />
                    <ColumnSelector
                        open={columnSelectorIsOpen}
                        onClose={handleCloseColumnSelector}
                        onClick={handleColumnSelectorClick}
                        columns={columns}
                        columnOrder={columnOrder}
                        items={getColumnSelectorItems()}
                        anchorEl={anchorEl}
                    />
                </div>
            </div>
        </div>
    );
}
