import './Body.css'

const Body = ({messages, user}) => {

    const isRecipient = (name) => {
        return name === user.login
    }

    return (
        <div className={"body-container"}>
            {messages.map((item) => (
                <div key={item.id} className={"chats"}>
                    <div className={isRecipient(item.name) ? 'senderName' : ''}>
                        {isRecipient(item.name) ? 'Вы' : item.name}
                    </div>
                    <div className={isRecipient(item.name) ? 'messageSender' : 'messageRecipient'}>
                        <div>{item.text}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Body