import {useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";


const MessageBlock = ({socket, user, status}) => {
    const [message, setMessage] = useState("")
    const [room, setRoom] = useState("")

    const sendMessage = (e) => {
        e.preventDefault()
        if (message.length > 0 && user.id) {
            socket.emit('new-message', {
                text: message,
                name: user.login,
                id: `${socket.id}--${Math.random()}`,
                socketID: socket.id
            })
        }
        setMessage('')
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendMessage(e)
        } else {
            socket.emit('typing', `${user.login} is typing ...`)
        }
    }

    function joinRoom() {
        console.log('присоединится к ', room)
    }

    return (
        <Grid container sx={{alignItems: "center", width: "100%", paddingTop: "20px"}}>
            <Grid item sx={{flex: "auto"}}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="message"
                    name="message"
                    label="Сообщение"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                />
            </Grid>
            <Grid item sx={{minWidth: 150}}>
                <Button onClick={sendMessage}>Отправить</Button>
            </Grid>
        </Grid>

        // <Grid container sx={{alignItems: "center", width: "100%"}}>
        //     <Grid item sx={{flex: "auto"}}>
        //         <TextField
        //             autoFocus
        //             margin="dense"
        //             id="room"
        //             name="room"
        //             label="Комната"
        //             type="text"
        //             fullWidth
        //             variant="outlined"
        //             value={room}
        //             onChange={e => setRoom(e.target.value)}
        //             inputProps={{
        //                 autoComplete: 'off'
        //             }}
        //         />
        //     </Grid>
        //     <Grid item sx={{minWidth: 150}}>
        //         <Button onClick={joinRoom}>Войти</Button>
        //     </Grid>
        // </Grid>

    )

};

export default MessageBlock;