import {ENTITIES, TYPES} from "../../../utils/constants";


export const fields = [
    {
        name: "status",
        label: "Статус",
        type: TYPES.SELECT,
        entityName: ENTITIES.STATUS,
        initialValue: 1,
        visible: (mode) => mode !== 'add',
    },
    {
        name: 'author',
        label: 'Автор',
        type: TYPES.SELECT,
        entityName: ENTITIES.EMPLOYEE,
        initialValue: (mode, user) => user.employeeId,
        visible: false,
    },
    {
        name: 'responsible',
        label: 'Ответственный',
        type: TYPES.SELECT,
        entityName: ENTITIES.EMPLOYEE,
    },
    {
        name: 'startDate',
        label: 'Приступить',
        type: TYPES.DATE,
        rowBreakBefore: true,
    },
    {
        name: 'endDate',
        label: 'Завершить к',
        type: TYPES.DATE,
    },
    {
        name: 'description',
        label: 'Что делать',
        multiline: true,
        grid: {xs: 12, sm: 12}
    },
    {
        name: 'lastAction',
        label: 'Что сделано',
        grid: {xs: 12, sm: 12},
        visible: (mode) => mode !== 'add'
    },
    // {
    //     name: 'agreementId',
    //     initialValue: 2,
    //     visible: false,
    // },
]


