import CardContainer from "../../containers/CardContainer";
import {ENTITIES} from "../../utils/constants";


const NotificationView = (props) => {
    return (
        <CardContainer
            tableTitle={"Уведомления"}
            viewName={ENTITIES.NOTIFICATION}
            {...props}
        />
    );
};

export default NotificationView;
