import React from 'react';
import IconButton from "@mui/material/IconButton";
import {Tooltip} from "@mui/material";


export default function ToolbarButton({onClick, tooltipTitle, name, disabled, icon}) {
    return (
        <>
            {disabled ?
                <IconButton name={name} disabled={disabled}>
                    {icon}
                </IconButton>
                :
                <Tooltip id={"tooltip"} title={tooltipTitle}>
                    <IconButton onClick={onClick} name={name}>
                        {icon}
                    </IconButton>
                </Tooltip>
            }
        </>
    );
};
