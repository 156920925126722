import React from 'react';
import Drawer from "@mui/material/Drawer";
import {DRAWER_WIDTH} from "../../../utils/constants";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {getIconByName} from "../../../utils/icons";
import ListItemText from "@mui/material/ListItemText";
import {styled} from "@mui/material/styles";


const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const menuItems = (roleId) => {
    let menus = [
        {
            name: 'agreements',
            title: 'Договоры',
            link: '/agreements',
            icon: 'agreements'
        },
        {
            name: 'tasks',
            title: 'Задачи',
            link: '/tasks',
            icon: 'tasks',
            divider: true,
        },
        {
            name: 'customers',
            title: 'Контрагенты',
            link: '/customers',
            icon: 'customers',
        },
        {
            name: 'employees',
            title: 'Сотрудники',
            link: '/employees',
            icon: 'employees'
        },
        {
            name: 'notifications',
            title: 'Уведомления',
            link: '/notifications',
            icon: 'notifications'
        },

    ]
    if (roleId === 1 || true) {
        menus.push({
            name: 'users',
            title: 'Пользователи',
            link: '/users',
            icon: 'users'
        })
    }

    return menus
}

const MenuDrawer = ({onClose, open, onClickItemMenu, user}) => {
    return (
        <Drawer
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <DrawerHeader>
                <Typography variant="h6" noWrap component="div">
                    Реестр договоров
                </Typography>
                <IconButton onClick={onClose}>
                    <ChevronLeftIcon/>
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List>
                {menuItems(user.roleId).map((item) => {
                        return (
                            <div key={item.name}>
                                <ListItem name={item.name} key={item.name} disablePadding
                                          onClick={() => onClickItemMenu(item)}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {getIconByName(item.icon)}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title}/>
                                    </ListItemButton>
                                </ListItem>
                                {item.divider && <Divider key={`${item.name}-divider`}/>}
                            </div>);
                    }
                )}
            </List>
        </Drawer>
    );
};

export default MenuDrawer;