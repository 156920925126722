import {createSelector, createSlice} from '@reduxjs/toolkit'

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {},
  reducers: {

    setOpenDialog: {
      reducer(state, action) {
        state[action.meta] = {...action.payload}
      },
      prepare(meta, payload) {
        return {
          meta, payload
        }
      }
    },

    setFieldValues: {
      reducer(state, action) {
        state[action.meta].values = action.payload;
      },
      prepare(meta, payload) {
        return {
          meta,
          payload
        }
      }
    },


    setFieldInitialValues: {
      reducer(state, action) {
        state[action.meta].initialValues = action.payload;
      },
      prepare(meta, payload) {
        return {
          meta,
          payload
        }
      }
    },

    setFieldValue: {
      reducer(state, action) {
        state[action.meta].values = {...state[action.meta].values, [action.payload.name]: action.payload.value};
      },
      prepare(meta, name, value) {
        return {
          meta,
          payload: {
            name,
            value
          }
        }
      }
    },
  }
});

export const selectDialogData = (name) => (state) => state.dialog[name];

export const selectDialogDataValueByName = (dialogName, fieldName) => (state) => {
  return state.dialog[dialogName].values && state.dialog[dialogName].values[fieldName]
};

export const selectEntitiesByEntityName = (entityName) => (state) => {
  return state.data.entities[entityName] || []
};

export const selectEntityByFieldName = (dialogName, fieldName, entityName) => createSelector(
    [selectDialogDataValueByName(dialogName, fieldName),
        selectEntitiesByEntityName(entityName)],
    (value, entities) => entities.find(i => i.id === value)
)

export const {
  setOpenDialog,
  setFieldValue,
  setFieldValues,
  setFieldInitialValues,
} = dialogSlice.actions;

export default dialogSlice.reducer;
