import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Body from "./Body/Body";
import MessageBlock from "./MessageBlock/MessageBlock";
import {SocketContext} from "../../context/socket";
import ChatToolbar from "./ChatToolbar/ChatToolbar";
import {selectUser} from "../../store/UserSlice";
import MainDrawer from "../../components/MainDrawer";
import './ChatView.css'
import {setTableTitle} from "../../store/MainBarSlice";
import {setParentParams} from "../../store/DataSlice";


const ChatView = () => {
    const [messages, setMessages] = useState([])
    const [status, setStatus] = useState('')
    const [room, setRoom] = useState('')
    const socket = useContext(SocketContext)
    const user = useSelector(selectUser)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTableTitle({tableTitle: 'Чат'}))
        localStorage.setItem('currentView', JSON.stringify({tableTitle: 'Чат'}))
    }, [])

    useEffect(() => {
        socket.on('response-typing', (data) => {
            setStatus(data)
            setTimeout(() => {
                setStatus('')
            }, 2000)
        })
    }, [socket])

    useEffect(() => {
        socket.on('response', (data) => {
            setMessages((prev) => [...prev, data])
        })
    }, [socket])


    return (
        <MainDrawer>
            <div className={"chat-container"}>
                <ChatToolbar room={room}/>
                <Body messages={messages} socket={socket} user={user}/>
                <MessageBlock socket={socket} user={user}/>
                <Grid className="chat-status"><p>{status}</p></Grid>
            </div>
        </MainDrawer>
    );
};

export default ChatView;