import {ENTITIES, TYPES} from "../../../utils/constants";


export const fields = [
  {
    name: 'date',
    label: 'Дата',
    type: TYPES.DATE,
  },
  {
    name: "bill",
    label: "Счет",
    type: TYPES.SELECT,
    entityName: ENTITIES.BILL,
  },
  {
    name: 'income',
    label: 'Приход',
    type: TYPES.DECIMAL,
    interval: {min: 0},
  },
  {
    name: 'expense',
    label: 'Расход',
    type: TYPES.DECIMAL,
    interval: {min: 0},
  },
  {
    name: 'description',
    label: 'Примечание',
    multiline: true,
    grid: {xs: 12, sm: 12}
  },
];

