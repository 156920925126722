import React from 'react';
import {tableButtons, columns, sorting, getTableFeatures, tableMenuItems} from './templates/table'
import {fields} from './templates/dialog'
import ViewContainer from "../../containers/ViewContainer";
import {ENTITIES} from "../../utils/constants";


const CustomersView = () => {
    return (
        <ViewContainer
            tableTitle={"Контрагенты"}
            viewName={ENTITIES.CUSTOMER}
            buttons={tableButtons(`${ENTITIES.CUSTOMER}Dialog`)}
            menuItems={tableMenuItems(`${ENTITIES.CUSTOMER}Dialog`)}
            fields={fields}
            addTitle={"Добавить контрагента"}
            editTitle={"Изменить контрагента"}
            columns={columns}
            sorting={sorting}
            tableFeatures={getTableFeatures()}
            maxWidth="sm"
        />
    );
};

export default CustomersView;
