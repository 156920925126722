import {Routes, Route, Navigate} from 'react-router-dom'
import {useSelector} from "react-redux";
import {authRoutes, publicRoutes} from "./routes";
import {LOGIN_ROUTE} from "../../utils/constants";


const AppRouter = () => {
    const user = useSelector(state => state.user);

    return (
        <Routes>
            {user.isAuth && authRoutes.map(({path, element}) =>
                <Route key={path} path={path} element={element}/>
            )}
            {publicRoutes.map(({path, element}) =>
                <Route key={path} path={path} element={element}/>
            )}
            <Route path="*" element={<Navigate to={LOGIN_ROUTE} replace/>}/>
        </Routes>
    );
};

export default AppRouter;
