import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {Avatar, Card, CardActionArea, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import {setTableTitle} from "../store/MainBarSlice";
import {decTotalEntity, setEntityWithoutTotal, setTotalEntity} from "../store/DataSlice";
import {
    deleteNotifications,
    loadData,
    loadUnreadNotificationsCount,
    markReadNotifications,
    updateData
} from "../http/dataAPI";
import {ENTITIES} from "../utils/constants";
import {selectNotificationAlert} from "../store/NotificationAlertSlice";
import ToolbarButton from "../components/ToolButton";
import './CardContainer.css'
import MainDrawer from "../components/MainDrawer";


const CardContainer = ({tableTitle, viewName}) => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [notifications, setNotifications] = useState([])
    const notificationAlert = useSelector(selectNotificationAlert)
    const [options, setOptions] = useState({
        filters: ['all'],
        sorting: {column: 'updated', direction: 'desc'}
    })

    const handleChange = (event, newMode) => {
        setOptions((prev) => ({...prev, filters: [newMode]}))
        setIsLoading(false)
    };

    const handleClickAllReadButton = () => {
        markReadNotifications()
            .then(setIsLoading(false))
        loadUnreadNotificationsCount()
            .then(data => {
                dispatch(setTotalEntity({name: ENTITIES.NOTIFICATION, data}))
            })
    }

    const handleClickDeleteAllButton = () => {
        deleteNotifications().then(setIsLoading(false))
        loadUnreadNotificationsCount()
            .then(data => {
                dispatch(setTotalEntity({name: ENTITIES.NOTIFICATION, data}))
            })
    }

    useEffect(() => {
        dispatch(setTableTitle({tableTitle, viewName}))
        localStorage.setItem('currentView', JSON.stringify({tableTitle, viewName}))
        if (!isLoading) {
            loadData(ENTITIES.NOTIFICATION, options)
                .then(data => {
                    setNotifications(data.rows)
                    dispatch(setEntityWithoutTotal({name: ENTITIES.NOTIFICATION, data}))
                });
            setIsLoading(true)
        }
    }, [isLoading, options]);


    useEffect(() => {
        if (notificationAlert.content) {
            loadData(ENTITIES.NOTIFICATION, options)
                .then(data => {
                        setNotifications(data.rows)
                        dispatch(setEntityWithoutTotal({name: ENTITIES.NOTIFICATION, data}))
                    }
                );
        }
    }, [notificationAlert]);

    const dateToStr = (value) => {
        const date = new Date(value);
        const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
        return date.toLocaleDateString('ru-RU', options).split('/').reverse().join('-');
    }


    function handleClick(item) {
        if (!item.isRead) {
            updateData(ENTITIES.NOTIFICATION, item.id, {isRead: true})
                .then((result) => {
                    if (result.status === 200) {
                        setNotifications((prev) => {
                            return prev.map(i => i.id === item.id ? {...i, isRead: true} : i)
                        })
                        dispatch(decTotalEntity(ENTITIES.NOTIFICATION))
                    }
                });
        }
    }

    return (
        <MainDrawer>
            <div className={'card-container'}>
                <div className={"card-toolbar-container"}>
                    <ToggleButtonGroup
                        color="primary"
                        value={options.filters && options.filters[0]}
                        exclusive
                        onChange={handleChange}
                        aria-label="mode"
                    >
                        <ToggleButton value='all'>Все</ToggleButton>
                        <ToggleButton value='read'>Прочитанные</ToggleButton>
                        <ToggleButton value='unread'>Не прочитанные</ToggleButton>
                    </ToggleButtonGroup>

                    <div className={"tool-buttons-container"}>
                        <ToolbarButton
                            onClick={handleClickAllReadButton}
                            tooltipTitle={'Отметить все как прочитанные'}
                            name="mark-all-read"
                            icon={<DraftsOutlinedIcon/>}
                        />
                        <ToolbarButton
                            onClick={handleClickDeleteAllButton}
                            tooltipTitle={'Удалить все'}
                            name="delete-all"
                            icon={<DeleteOutlinedIcon/>}
                        />
                    </div>
                </div>

                <div className={"card-list-container"}>
                    {notifications.map(item =>
                        <Card key={item.id} className={"card"}>
                            <CardActionArea onClick={() => handleClick(item)}>
                                <CardContent>
                                    <Grid container className={'card-content'}>
                                        <Grid item>
                                            <Avatar sx={{bgcolor: !item.isRead && "#e5eb97", color: "black"}}>
                                                {item.isRead ? <DraftsOutlinedIcon/> : <MailOutlineOutlinedIcon/>}
                                            </Avatar>
                                        </Grid>
                                        <Grid item style={{marginLeft: '8px'}}>
                                            <Typography variant="h5" component="div">
                                                {item.heading}
                                            </Typography>
                                            <Typography variant="caption" component="div">
                                                {dateToStr(item.updated)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="body1" color="text.secondary">
                                            {item.content}
                                        </Typography>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    )}
                </div>
            </div>
        </MainDrawer>
    );
};

export default CardContainer;