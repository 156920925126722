import React, {useEffect} from "react";
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";

import {createTheme} from '@mui/material/styles';
import ThemeProvider from "@mui/material/styles/ThemeProvider";

import AppRouter from "./components/Router/AppRouter";
import {check, subscribe, subscribeSSE} from "./http/userAPI";
import {selectUser, setUser} from "./store/UserSlice";
import {AGREEMENTS_ROUTE, ENTITIES} from "./utils/constants";
import AlertDialog from "./components/Modal/AlertDialog";
import ConfirmDeleteDialog from "./components/Modal/ConfirmDeleteDialog";
import NotificationSnackbar from "./components/Snackbar";
import {useSocket} from "./hooks/useSocket";
import './App.css';
import {loadUnreadNotificationsCount} from "./http/dataAPI";
import {setTotalEntity} from "./store/DataSlice";


const theme = createTheme({
        palette: {
            primary: {
                main: '#81c784',
            },
            secondary: {
                main: '#dce775',
            },
        },
        components: {
            MuiTextField: {
                defaultProps: {
                    size: "small"
                }
            },
            MuiAlert: {
                styleOverrides: {
                    message: {
                        padding: 0
                    }
                }
            }
        }
    },
);

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser)
    useSocket()

    useEffect(() => {
        check().then(data => {
            dispatch(setUser(data));
            navigate(AGREEMENTS_ROUTE)
        }).catch(e => console.log(e.message)
        )
    }, []);

    useEffect(() => {
        if (user.isAuth) {
            loadUnreadNotificationsCount().then(data => {
                dispatch(setTotalEntity({name: ENTITIES.NOTIFICATION, data}))
            })
        }
    }, [user]);

    useEffect(() => {
        if (user && user.isAuth && user.autoUpdate) {
            // subscribe(user, dispatch).then() // lp
            subscribeSSE(user, dispatch).then() // sse
        }
    }, [user])

    return (
        <ThemeProvider theme={theme}>
            <AppRouter/>
            <AlertDialog/>
            <ConfirmDeleteDialog/>
            <NotificationSnackbar/>
        </ThemeProvider>
    );
}

export default App;
