import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField/TextField";
import {selectDialogDataValueByName, setFieldValue} from "../../store/DialogSlice";
import {fetchEntityData} from "../../http/helper";


const AutocompleteField = ({name, label, entityName, dialogName}) => {

    const dispatch = useDispatch();
    const initValue = useSelector(selectDialogDataValueByName(dialogName, name))
    const [options, setOptions] = useState([])
    const [value, setValue] = useState(null)
    // const [textValue, setTextValue] = useState('')

    useEffect(() => {
        if (initValue) {
            setValue(initValue.id)
            setOptions([initValue])
        }
    }, [initValue])

    // 1. синхронная загрузка списков
    const handleTextFieldChange = (e) => {
        const params = {search: e.target.value, pageSize: 5, pageNumber: 1}
        fetchEntityData(entityName, params).then(data => {
            const newOptions = data.rows.map(item => ({id: item.id, name: item.name}))
            setOptions(newOptions)
        }).then()
        const item = options.find(option => option.name === e.target.value)
        setValue(item ? item.id : null);
    }

    // 2. загрузка списков с задержкой
    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //         const params = {search: textValue, pageSize: 5, pageNumber: 1}
    //         fetchEntityData(entityName, params).then(data => {
    //             const newOptions = data.rows.map(item => ({id: item.id, name: item.name}))
    //             setOptions(newOptions)
    //         }).then()
    //         const item = options.find(option => option.name === textValue)
    //         setValue(item ? item.id : null);
    //     }, 500);
    //     return () => clearTimeout(timeoutId)
    //
    // }, [textValue])

    // const handleTextFieldChange = (e) => setTextValue(e.target.value)


    const handleAutocompleteChange = (event, value, reason) => {
        if (reason === "clear") {
            setOptions([])
            setValue(null)
            dispatch(setFieldValue(dialogName, name, null))
        } else if (reason === "selectOption") {
            dispatch(setFieldValue(dialogName, name, value.id))
            setValue(value.id)
        }
    };

    return (
        <Autocomplete
            id={name}
            fullWidth
            freeSolo
            value={value ? options.find((option) => {
                return value === option.id
            }) ?? null : null}
            getOptionLabel={(option) => option.name}
            onChange={handleAutocompleteChange}
            options={options}
            renderInput={
                (params) => <TextField {...params} label={label} onChange={handleTextFieldChange}/>
            }
        />
    );
};

export default AutocompleteField;
