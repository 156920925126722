import {createSlice} from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
        isAuth: false,
        id: null,
        login: null,
        instance: null,
        autoUpdate: null,
        roleId: null,
        employeeId: null,
    },
    reducers: {
        setUser(state, action) {
            state.isAuth = true;
            state.id = action.payload.id;
            state.login = action.payload.login;
            state.instance = action.payload.instance;
            state.autoUpdate = action.payload.autoUpdate;
            state.roleId = action.payload.roleId;
            state.employeeId = action.payload.employeeId;
        },

        removeUser(state) {
            state.isAuth = false;
            state.id = null;
            state.login = null;
        }

    }
});

export const selectUser = (state) => state.user;

export const {setUser, removeUser} = userSlice.actions;

export default userSlice.reducer;
