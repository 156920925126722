import React from 'react';
import dayjs from "dayjs";
import "dayjs/locale/ru";

import {ruRU} from '@mui/x-date-pickers/locales';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import {selectDialogDataValueByName, setFieldValue} from "../../store/DialogSlice";
import {useDispatch, useSelector} from "react-redux";
import {convertDatetoString} from "../../utils/dateUtils";


export default function DateField({name, label, dialogName}) {
  const dispatch = useDispatch();
  const value = useSelector(selectDialogDataValueByName(dialogName, name)) || null;

  const handleChange = (newValue) => {
    if (newValue) {
      const date = new Date(newValue);
      dispatch(setFieldValue(dialogName, name, convertDatetoString(date)));
    } else
      dispatch(setFieldValue(dialogName, name, null));
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale="ru"
    >
      <div style={{paddingTop: 8}}>
        <DatePicker
          name={name}
          label={label}
          value={dayjs(value)}
          format="DD.MM.YYYY"
          onChange={(newValue) => handleChange(newValue)}
          slotProps={{
            textField: {
              fullWidth: true,
              error: false,
            },
            // toolbar: {toolbarFormat: 'ddd DD MMMM', hidden: false},
            // tabs: {hidden: false,},
            actionBar: {actions: ['clear', 'today'],},
          }}
        />
      </div>
    </LocalizationProvider>
  );
}
