import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import {Badge} from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import {selectTotalCount} from "../../../store/DataSlice";
import {ENTITIES, NOTIFICATIONS_ROUTE} from "../../../utils/constants";


const NotificationButton = () => {
    const navigate = useNavigate()
    const [count, setCount] = useState()
    const totalCount = useSelector(selectTotalCount(ENTITIES.NOTIFICATION))

    useEffect(() => {
        setCount(totalCount)
    }, [totalCount])

    function handleNotification() {
        navigate(NOTIFICATIONS_ROUTE)
    }


    return (
        <IconButton aria-label="cart" onClick={handleNotification}>
            <Badge badgeContent={count} color="secondary">
                <NotificationsNoneOutlinedIcon/>
            </Badge>
        </IconButton>
    );
};

export default NotificationButton;