import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import DataTableToolBar from "./DataTableToolBar";
import DataTable from "./DataTable/DataTable";
import DataTablePagination from "./DataTablePagination";
import Loader from '../../components/Loader'

import {loadDataEntity} from "../../http/helper";
import {
    selectColumnHidden,
    selectColumnOrder,
    selectColumnSearch,
    selectColumnWidths,
    selectLoaderState,
    selectPageSizes,
    selectParams,
    selectTotalCount,
    setInitUrlParamsDataTable,
    setLoaderStateDataTable,
    setParameterDataTable,
} from "../../store/DataSlice";
import './AdvancedDataTable.css'
import {settings, SETTINGS_CONFIG_NAME} from "../../utils/constants";
import {
    checkTableFeature,
    getColumnOrder,
    getColumnWidths,
    needToUpdateSettingsConfig
} from "../../utils/helpers";


const AdvancedDataTable = ({
                               name,
                               buttons,
                               menuItems,
                               columns,
                               sorting,
                               entity,
                               dialogName,
                               parentParams,
                               tableFeatures,
                           }) => {
    const getAccessKey = () => {
        if (parentParams) return `${parentParams.name}-${name}`
        else return name
    }

    const accessKey = getAccessKey()

    const dispatch = useDispatch()
    const pageSizes = useSelector(selectPageSizes(accessKey))
    const totalCount = useSelector(selectTotalCount(entity))
    const params = useSelector(selectParams(accessKey))
    const columnOrder = useSelector(selectColumnOrder(accessKey))
    const columnWidths = useSelector(selectColumnWidths(accessKey))
    const columnHidden = useSelector(selectColumnHidden(accessKey))
    const columnSearch = useSelector(selectColumnSearch(accessKey))
    const isLoading = useSelector(selectLoaderState(accessKey))


    useEffect(() => {
        const columnOrder = getColumnOrder(columns)
        const columnWidths = getColumnWidths(columns)
        const hiddenColumns = []
        const columnSearch = []

        let localStorageConfig = JSON.parse(localStorage.getItem(SETTINGS_CONFIG_NAME))
        const localStorageTableType = localStorageConfig && localStorageConfig[accessKey]?.tableType
        const config = {
            tableType: localStorageTableType ? localStorageTableType : settings.PAGING,

            [settings.HIDDING]: {
                enabled: (() => {
                    const result = checkTableFeature(settings.HIDDING, tableFeatures);
                    return result ? result.value : false;
                })(),
                value: hiddenColumns,
            },
            [settings.RESIZING]: {
                enabled: (() => {
                    const result = checkTableFeature(settings.RESIZING, tableFeatures);
                    return result ? result.value : false;
                })(),
                value: columnWidths,
            },
            [settings.REORDERING]: {
                enabled: (() => {
                    const result = checkTableFeature(settings.REORDERING, tableFeatures);
                    return result ? result.value : false;
                })(),
                value: columnOrder,
            },
            [settings.COLUMN_SEARCH]: {
                enabled: (() => {
                    const result = checkTableFeature(settings.COLUMN_SEARCH, tableFeatures);
                    return result ? result.value : false;
                })(),
                value: columnSearch,
            },
        };

        const shouldLocalStorageUpdate = needToUpdateSettingsConfig({
            accessKey: accessKey,
            currentConfig: config,
            localStorageConfig,
        });
        if (!localStorageConfig) {
            const initConfig = {[accessKey]: config};
            localStorage.setItem(SETTINGS_CONFIG_NAME, JSON.stringify(initConfig))
        } else if (shouldLocalStorageUpdate || !localStorageConfig[accessKey]) {
            localStorageConfig[accessKey] = config;
            localStorage.setItem(SETTINGS_CONFIG_NAME, JSON.stringify(localStorageConfig))
        }

        localStorageConfig = JSON.parse(localStorage.getItem(SETTINGS_CONFIG_NAME))
        dispatch(setParameterDataTable(accessKey, {columnOrder: localStorageConfig[accessKey][settings.REORDERING].value}))
        dispatch(setParameterDataTable(accessKey, {columnWidths: localStorageConfig[accessKey][settings.RESIZING].value}))
        dispatch(setParameterDataTable(accessKey, {columnHidden: localStorageConfig[accessKey][settings.HIDDING].value}))
        dispatch(setParameterDataTable(accessKey, {columnSearch: localStorageConfig[accessKey][settings.COLUMN_SEARCH].value}))

        const initParams = {
            sorting,
            pageSize: 10,
            pageNumber: 1,
            filters: [],
            filterBy: localStorageConfig[accessKey][settings.COLUMN_SEARCH].value || null,
            search: null,
        }
        dispatch(setInitUrlParamsDataTable(accessKey, initParams))

        // начальный статус загруженных данных false
        dispatch(setLoaderStateDataTable(accessKey, false))
    }, [dispatch])

    useEffect(() => {
        if (params && !isLoading) {
            loadDataEntity(dispatch, name, entity, params, accessKey).then();
        }
    }, [dispatch, entity, params, isLoading]);

    return (
        <div className={"datatable-container"}>
            <DataTableToolBar
                name={name}
                buttons={buttons}
                entity={entity}
                params={params}
                columns={columns}
                columnOrder={columnOrder}
                columnHidden={columnHidden}
                columnSearch={columnSearch}
                accessKey={accessKey}
            />
            <DataTable
                name={name}
                columns={columns}
                menuItems={menuItems}
                columnWidths={columnWidths}
                columnOrder={columnOrder}
                columnHidden={columnHidden}
                entity={entity}
                params={params}
                dialogName={dialogName}
                parentParams={parentParams}
                accessKey={accessKey}
            />
            <DataTablePagination
                name={name}
                entity={entity}
                pageSizes={pageSizes}
                totalCount={totalCount}
                params={params}
            />
            {!isLoading && <Loader/>}
        </div>
    );
};

export default AdvancedDataTable;
