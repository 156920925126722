import React from 'react';
import {tableButtons, columns, sorting, getTableFeatures, tableMenuItems} from './templates/table'
import {fields} from './templates/dialog'
import ViewContainer from "../../containers/ViewContainer";
import {ENTITIES} from "../../utils/constants";


const TaskView = (props) => {
    return (
        <ViewContainer
            tableTitle={"Задачи"}
            viewName={ENTITIES.TASK}
            buttons={tableButtons(`${ENTITIES.TASK}Dialog`)}
            menuItems={tableMenuItems(`${ENTITIES.TASK}Dialog`)}
            fields={fields}
            addTitle={"Добавить задачу"}
            editTitle={"Изменить задачу"}
            columns={columns}
            sorting={sorting}
            tableFeatures={getTableFeatures()}
            maxWidth="md"
            {...props}
        />
    );
};

export default TaskView;
