import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setParameterDataTable, setParams} from "../../../../store/DataSlice";
import {settings, TYPES} from "../../../../utils/constants";
import {classnames, updateTableSettingLocalStorage} from "../../../../utils/helpers";
import {loadDataEntity} from "../../../../http/helper";
import '../DataTable.css'


function DataTableHeader({name, columnOrder, columnWidths, columnHidden, columns, params, entity, accessKey}) {

    const dispatch = useDispatch();
    const [order, setOrder] = useState()
    const [divider, setDivider] = useState()
    const [draggedColumn, setDraggedColumn] = useState()
    const [isDraggable, setIsDraggable] = useState(true)

    useEffect(() => {
        params && setOrder(params.sorting.direction)
    }, [params])

    function handleClickHeader(column) {
        const currentSorting = params.sorting
        let newSorting;
        const fullColumnName = column.type === TYPES.LINK || column.type === TYPES.FILE ? column.id + ".name" : column.id

        if (currentSorting.column === fullColumnName) {
            if (currentSorting.direction === 'asc') {
                newSorting = {column: currentSorting.column, direction: 'desc'}
                setOrder('desc')
            } else {
                newSorting = {column: currentSorting.column, direction: 'asc'}
                setOrder('asc')
            }
        } else {
            newSorting = {column: fullColumnName, direction: 'asc'}
            setOrder('asc')
        }

        const newParams = {...params, sorting: newSorting}
        dispatch(setParams(accessKey, newParams))
        loadDataEntity(dispatch, name, entity, newParams, accessKey).then();
    }

    function isSortable(column) {
        const fullColumnName = column.type === TYPES.LINK || column.type === TYPES.FILE ? column.id + ".name" : column.id
        return params?.sorting.column === fullColumnName
    }

    // ресайз колонок
    const handleMouseDown = (event, column) => {
        setIsDraggable(false)
        const coords = event.target.parentNode.parentNode.getBoundingClientRect();
        const resizeContainerWidth = 8

        document.onmousemove = e => {
            const delta = e.pageX - coords.right + resizeContainerWidth
            const colWidth = columnWidths.find(c => c.name === column.id).width
            if (colWidth + delta > 0) {
                const newColumnWidths = columnWidths.map(col => col.name === column.id ? {
                    ...col,
                    width: col.width + delta
                } : col)
                dispatch(setParameterDataTable(accessKey, {columnWidths: newColumnWidths}))
                updateTableSettingLocalStorage(accessKey, settings.RESIZING, newColumnWidths)
            } else document.onmousemove = null
        }
        document.onmouseup = () => {
            document.onmousemove = null
            setIsDraggable(true)
        }
    }

    // перенос колонки
    const handleOver = () => setDivider(null)
    const handleOut = (column) => setDivider(column)
    const dragStartHandler = (e, column) => setDraggedColumn(column.id)
    const dragOverHandler = (e) => e.preventDefault()
    const dropHandler = (e, column) => {
        e.preventDefault()
        let newColumnOrder = [...columnOrder]
        const currentOrderIndex = columnOrder.findIndex(col => col === draggedColumn)
        const newOrderIndex = columnOrder.findIndex(col => col === column.id)
        newColumnOrder[currentOrderIndex] = columnOrder[newOrderIndex]
        newColumnOrder[newOrderIndex] = columnOrder[currentOrderIndex]
        setDraggedColumn(null)
        dispatch(setParameterDataTable(accessKey, {columnOrder: newColumnOrder}))
        updateTableSettingLocalStorage(accessKey, settings.REORDERING, newColumnOrder)
    }

    const isFiltered = (columnName) => {
        return params.filterBy && params.filterBy.find(item => item.id === columnName)
    }

    return (
        <thead id={`tableHeader-${name}`}>
        <tr>
            <th/>
            {columnOrder && columnOrder.map(col => {
                const column = columns.find(c => c.id === col && !columnHidden.includes(col))
                if (column) {
                    return (
                        <th key={column.id}
                            scope="col"
                            onMouseOut={handleOver}
                            onMouseOver={() => handleOut(column.id)}
                            onDragStart={(e) => dragStartHandler(e, column)}
                            onDragOver={(e) => dragOverHandler(e)}
                            onDrop={(e) => dropHandler(e, column)}
                        >
                            <div className={"headerCellContainer"}>
                                <div
                                    className={classnames("sortLabel", {
                                        "filtered-column": isFiltered(column.id)
                                    }, [])}
                                    style={{flexDirection: column.type === TYPES.DECIMAL ? 'row-reverse' : 'row'}}
                                    onClick={() => handleClickHeader(column)}
                                    draggable={isDraggable}
                                >
                                    <span className={"sortTitle"}>{column.label}</span>
                                    {
                                        isSortable(column) && <span
                                            className={classnames("sortArrow", {
                                                "arrowUp": order === "asc",
                                                "arrowDown": order === "desc"
                                            }, [])}
                                        >
                                            &#9658;
                                                </span>
                                    }
                                </div>

                                <div
                                    className={"cellResize"}
                                    key={column.id}
                                    data-name={column.id}
                                    onMouseDown={(e) => handleMouseDown(e, column)}
                                >
                                    <div className={classnames(
                                        "resizeMarkCommon resizeMarkLeft", {
                                            "showDividers": divider === column.id
                                        }, [])}/>
                                    <div className={classnames(
                                        "resizeMarkCommon resizeMarkRight", {
                                            "showDividers": divider === column.id
                                        }, [])}
                                    />
                                </div>
                            </div>
                        </th>
                    )
                }
            })
            }
        </tr>
        </thead>

    );
}

export default DataTableHeader;