import * as React from 'react';
import {useDispatch} from "react-redux";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import Button from "@mui/material/Button";

import {setParams} from "../../store/DataSlice";
import {loadDataEntity} from "../../http/helper";
import './DataTablePagination.css'


export default function DataTablePagination({name, entity, pageSizes, totalCount, params}) {

    const dispatch = useDispatch()

    function getNavigationMessage() {
        if (params && totalCount) {
            const {pageNumber, pageSize} = params
            const pageFrom = (pageNumber - 1) * pageSize + 1
            const pageTo = pageNumber * pageSize > totalCount ? totalCount : pageNumber * pageSize
            return (
                <div className={"pagination-panel"}>
                    <span>показаны записи с {pageFrom} по {pageTo} из {totalCount}</span></div>
            )
        }
    }

    function handleChangePageSize(event) {
        const newParams = {...params, pageSize: event.target.value}
        dispatch(setParams(name, newParams))
        loadDataEntity(dispatch, name, entity, newParams).then();
    }

    function handlePageNumberChange(number) {
        const newParams = {...params, pageNumber: number}
        dispatch(setParams(name, newParams))
        loadDataEntity(dispatch, name, entity, newParams).then();
    }

    function getRowPerPage() {
        return (
            pageSizes ?
                <div className={"pagination-panel pagination-rowPerPage"}>
                    <span>Отображать строк на странице</span>
                    <TextField
                        id="pageSizes"
                        select
                        value={params.pageSize}
                        onChange={handleChangePageSize}
                        variant="standard"
                    >
                        {pageSizes.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                : null
        )
    }

    function createPaginationData(totalCount, pageSize, pageNumber) {

        const pageCount = Math.ceil(totalCount / pageSize)

        let panel = {
            previous: null,
            next: null,
            list: [],
        }

        pageNumber === 1 ? panel.previous = true : panel.previous = false
        pageNumber === pageCount ? panel.next = true : panel.next = false

        let list = []

        if (pageCount <= 4) {
            for (let i = 1; i <= pageCount; i++) {
                list.push(i)
            }
            panel.list = list
        } else if (([1, 2].includes(pageNumber)) && pageCount > 4) {
            for (let i = 1; i <= 3; i++) {
                list.push(i)
            }
            list.push(-1)
            list.push(pageCount)
            panel.list = list
        } else if (pageNumber === 3 && pageCount === 5) {
            for (let i = 1; i <= pageCount; i++) {
                list.push(i)
            }
            panel.list = list
        } else if (pageNumber === 3 && pageCount > 5) {
            for (let i = 1; i <= pageNumber + 1; i++) {
                list.push(i)
            }
            list.push(-1)
            list.push(pageCount)
            panel.list = list
        } else if (pageNumber === pageCount - 1 && pageCount >= 5) {
            list.push(1)
            list.push(-1)
            for (let i = pageNumber - 1; i <= pageCount; i++) {
                list.push(i)
            }
            panel.list = list
        } else if (pageNumber === pageCount && pageCount >= 5) {
            list.push(1)
            list.push(-1)
            for (let i = pageNumber - 2; i <= pageCount; i++) {
                list.push(i)
            }
            panel.list = list
        } else if (pageNumber === pageCount - 2 && pageCount >= 5) {
            list.push(1)
            list.push(-1)
            for (let i = pageNumber - 1; i <= pageCount; i++) {
                list.push(i)
            }
            panel.list = list
        } else if ((pageNumber <= pageCount - 3 && pageNumber > 3) && pageCount >= 5) {
            list.push(1)
            list.push(-1)
            for (let i = pageNumber - 1; i <= pageNumber + 1; i++) {
                list.push(i)
            }
            list.push(-1)
            list.push(pageCount)
            panel.list = list
        }
        return panel
    }

    function getPagination() {
        if (params && totalCount) {
            const {pageSize, pageNumber} = params
            const panel = createPaginationData(totalCount, pageSize, pageNumber)

            return (
                <div className={"pagination-panel"}>
                    <IconButton
                        disabled={panel.previous}
                        onClick={() => handlePageNumberChange(pageNumber - 1)}>
                        <ChevronLeft/>
                    </IconButton>

                    {panel.list.map((page, idx) => {
                        if (page > 0) {
                            return (
                                <Button
                                    key={idx}
                                    style={{
                                        minWidth: "auto",
                                        fontWeight: page === pageNumber && 600,
                                        fontSize: page === pageNumber && "larger"
                                    }}
                                    onClick={() => handlePageNumberChange(page)}
                                >
                                    {page}
                                </Button>
                            )
                        }
                        return <span key={idx}>...</span>
                    })}

                    <IconButton
                        disabled={panel.next}
                        onClick={() => handlePageNumberChange(pageNumber + 1)}>
                        <ChevronRight/>
                    </IconButton>
                </div>
            )
        }
    }

    return (
        <div className={"pagination-container"}>
            {getNavigationMessage()}
            {getRowPerPage()}
            {getPagination()}
        </div>
    );
}
