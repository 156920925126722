import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {setOpenDialog} from "../../../../store/DialogSlice";
import {selectEntityData, selectSelectedRowId, setRowSelected} from "../../../../store/DataSlice";
import {downloadFile} from "../../../../http/dataAPI";
import {classnames} from "../../../../utils/helpers";
import ActionMenu from "../../../../components/ActionMenu";
import {getTableMessages, TYPES} from "../../../../utils/constants";
import '../DataTable.css'


function DataTableBody({name, columnOrder, dialogName, entity, columns, menuItems, columnHidden, params}) {

    const dispatch = useDispatch();
    const rows = useSelector(selectEntityData(entity));
    const selectionRowId = useSelector(selectSelectedRowId(name))

    function handleRowDblClick(id) {
        dispatch(setRowSelected(name, id));
        dispatch(setOpenDialog(dialogName, {isOpen: true, mode: 'edit', values: {}, id}))
    }

    function getTableCell(column, value) {
        let output = value;

        if (column.type === TYPES.DATE && value) {
            const date = new Date(value);
            const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
            output = date.toLocaleDateString('ru-RU', options).split('/').reverse().join('-');
            return (
                <td key={column.id} style={{cursor: "pointer"}}>{output}</td>
            )
        } else if (column.type === TYPES.LINK) {
            output = value && column.getValue ? column.getValue(value) : value?.name;
            return (
                <td key={column.id} style={{cursor: "pointer"}}>{output}</td>
            )
        } else if (column.type === TYPES.FILE) {
            output = column.getValue ? column.getValue(value) : value?.name;
            return (
                <td key={column.id}
                    style={{cursor: "pointer"}}
                    onClick={() => value && downloadFile(value)}
                >
                    {output}
                </td>
            )
        } else if (column.type === TYPES.NUMBER || column.type === TYPES.DECIMAL) {
            return (
                <td key={column.id}
                    style={{
                        cursor: "pointer",
                        textAlign: "right"
                    }}>
                    {value && Number(value).toLocaleString('ru')}
                </td>
            )
        } else if (column.type === TYPES.BOOLEAN) {
            return (
                <td key={column.id}
                    style={{
                        cursor: "pointer",
                    }}>
                    {value && Boolean(value) ? 'Да' : 'Нет'}
                </td>
            )
        } else {
            return (
                <td key={column.id} style={{cursor: "pointer"}}>{value}</td>
            )
        }
    }

    function handleRowClick(id) {
        if (id !== selectionRowId) {
            dispatch(setRowSelected(name, id))
        } else {
            dispatch(setRowSelected(name, null))
        }
    }

    return (
        <tbody>
        {rows && rows.length > 0 ?
            rows.map((row) => {
                return (
                    <tr key={row.id}
                        onClick={() => handleRowClick(row.id)}
                        onDoubleClick={() => handleRowDblClick(row.id)}
                        className={classnames("", {tableRowIsSelected: row.id === selectionRowId}, [])}
                    >
                        <ActionMenu items={menuItems} row={row} entity={entity} params={params}/>

                        {columnOrder && columnOrder.map(colName => {
                            const column = columns.find(c => c.id === colName && !columnHidden.includes(colName))
                            if (column) {
                                const value = row[column.id];
                                return getTableCell(column, value)
                            }
                            return null
                        })}
                    </tr>
                );
            })
            :
            <tr>
                <td
                    className={"not_found-cell"}
                    colSpan={columns.length + 1}>
                    <big>{getTableMessages.noData}</big>
                </td>
            </tr>
        }
        </tbody>
    );
}

export default DataTableBody;