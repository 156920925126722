import {COLUMN_WIDTHS, settings, SORT, TYPES} from "../../../utils/constants";
import {addButton, editButton} from "../../common/buttons";
import {deleteActionItem, editActionItem, logOutActionItem} from "../../common/menuItems";

export const columns = [
    {
        id: 'login',
        label: 'Логин',
        type: TYPES.TEXT,
        minWidth: COLUMN_WIDTHS.LARGE
    },
    {
        id: 'autoUpdate',
        label: 'Автообновление',
        type: TYPES.BOOLEAN,
        minWidth: COLUMN_WIDTHS.LARGE
    },
    {
        id: 'role',
        path: 'role.name',
        label: 'Роль',
        type: TYPES.LINK,
        entityName: 'role',
        minWidth: COLUMN_WIDTHS.LARGE
    },
    {
        id: 'employee',
        path: 'employee.name',
        label: 'Сотрудник',
        type: TYPES.LINK,
        entityName: 'employee',
        minWidth: COLUMN_WIDTHS.LARGE
    },
];

export const tableButtons = (dialogName) => [
    {...addButton, dialogName}, {...editButton, dialogName}
];

export const tableMenuItems = (dialogName) => [
    {...editActionItem, dialogName}, {...deleteActionItem, dialogName}, logOutActionItem
]
export const sorting = {column: 'login', direction: SORT.ASC}

export function getTableFeatures() {
    return [
        {name: settings.HIDDING, value: true},
        {name: settings.FULL_TEXT_SEARCH, value: true},
        {name: settings.SEARCHING, value: true},
        {name: settings.REORDERING, value: true},
        {name: settings.RESIZING, value: true},
    ];
}