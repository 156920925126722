import io from "socket.io-client";
import {createContext} from "react";

export const socketURL = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BUILD_API_URL
    : process.env.REACT_APP_DEV_API_URL


const socketUp = true

export const socket = socketUp ? io.connect(socketURL) : io;

export const SocketContext = createContext();