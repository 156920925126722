import React from 'react';
import {NumericFormat} from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@mui/material/TextField";
import {selectDialogDataValueByName, setFieldValue} from "../../store/DialogSlice";


const NumberField = ({name, label, interval, dialogName, allowNegative}) => {
    const dispatch = useDispatch();
    const value = useSelector(selectDialogDataValueByName(dialogName, name)) || '';

    const handleChange = (e) => dispatch(setFieldValue(dialogName, name, e.target.value));

    const handleIsAllowed = (values) => {
        if (interval && values) {
            const {min, max} = interval;
            const {floatValue} = values;
            if (!floatValue) return true;
            if (floatValue < min) return false;
            if (floatValue > max) return false;
        }
        return true
    };

    return (
        <div style={{paddingTop: 8}}>
            <NumericFormat
                value={value}
                isAllowed={(values) => handleIsAllowed(values)}
                allowNegative={allowNegative}
                customInput={TextField}
                id={name}
                name={name}
                label={label}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                inputProps={{
                    style: {textAlign: "right"},
                    autoComplete: 'off'
                }}
            />
        </div>
    );
};

export default NumberField;


