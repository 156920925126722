import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {NumericFormat} from 'react-number-format';
import TextField from "@mui/material/TextField";
import {selectDialogDataValueByName, setFieldValue} from "../../store/DialogSlice";


const DecimalField = ({name, label, dialogName}) => {

  const dispatch = useDispatch();
  const value = useSelector(selectDialogDataValueByName(dialogName, name)) || '';

  function handleValuesChange(values) {
    dispatch(setFieldValue(dialogName, name, values.floatValue || null));
  }

  return (
    <div style={{paddingTop: 8}}>
      <NumericFormat
        onValueChange={(values) => handleValuesChange(values)}
        value={value}
        thousandSeparator={" "}
        allowNegative={false}
        customInput={TextField}
        id={name}
        name={name}
        label={label}
        fullWidth
        variant="outlined"
        inputProps={{
          style: {textAlign: "right"},
          autoComplete: 'off'
        }}
      />
    </div>
  );
};

export default DecimalField;


