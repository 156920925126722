import {useContext, useEffect} from "react";
import {SocketContext} from "../context/socket";
import {useSelector} from "react-redux";
import {selectUser} from "../store/UserSlice";


export const useSocket = () => {

    const socket = useContext(SocketContext)
    const user = useSelector(selectUser)


    useEffect(() => {
        socket.on("connect", () => {
            console.log("You connected with id: " + socket.id)
        })

        // изменения в таблицах
        socket.on('entity-notification', (data) => {
            console.log(`entity-notification: ${data.mode}, ${data.entity}, ${data.data.id}`)
        })
    }, [socket, user])

}