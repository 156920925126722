import React from 'react';
import {tableButtons, columns, sorting, getTableFeatures, tableMenuItems} from './templates/table'
import {fields} from './templates/dialog'
import ViewContainer from "../../containers/ViewContainer";
import {ENTITIES} from "../../utils/constants";


const ExecutionFactView = (props) => {
    return (
        <ViewContainer
            viewName={ENTITIES.EXECUTION_FACT}
            buttons={tableButtons(`${ENTITIES.EXECUTION_FACT}Dialog`)}
            menuItems={tableMenuItems(`${ENTITIES.EXECUTION_FACT}Dialog`)}
            fields={fields}
            addTitle={"Добавить акт выполненных работ"}
            editTitle={"Изменить акт выполненных работ"}
            columns={columns}
            sorting={sorting}
            tableFeatures={getTableFeatures()}
            maxWidth="sm"
            {...props}
        />
    );
};

export default ExecutionFactView;
