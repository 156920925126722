import React, {cloneElement, useContext} from 'react';
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import SelectField from "../../components/Form/SelectField";
import AutocompleteField from "../../components/Form/AutocompleteField";
import TextField from "../../components/Form/TextField";
import DateField from "../../components/Form/DateField";
import NumberField from "../../components/Form/NumberField";
import FileField from "../../components/Form/FileField";
import DecimalField from "../../components/Form/DecimalField";

import {selectDialogData, setFieldInitialValues, setFieldValues, setOpenDialog} from "../../store/DialogSlice";
import {loadData, saveData, updateData} from "../../http/dataAPI";
import {selectEntityDataById, selectParams, setEntity} from "../../store/DataSlice";
import {loadSelectFieldsEntities, loadOneRecordById} from "../../http/helper";
import {TYPES} from "../../utils/constants";
import DadataField from "../../components/Form/DadataField";
import CheckboxField from "../../components/Form/CheckboxField";
import {setOpenAlertDialog} from "../../store/ModalSlice";
import {selectUser} from "../../store/UserSlice";
import {SocketContext} from "../../context/socket";


export function renderField(layout, field, mode, dialogName) {
    const {
        name,
        label,
        entityName,
        type,
        getValue,
        grid,
        rowBreakBefore,
        interval,
        visible,
        multiline,
        allowNegative,
    } = field;

    const xs = grid && grid.xs ? grid.xs : 12;
    const sm = grid && grid.sm ? grid.sm : 6;

    const isVisible = visible === true || visible === undefined ||
        visible === null || (typeof visible === 'function' && visible(mode));

    const gridStyle = !isVisible ? {display: "none"} : {};

    if (rowBreakBefore) {
        layout.push(<div style={{width: '100%'}} key={`rowBreakBefore-${name}`}/>);
    }

    switch (type) {

        case TYPES.BOOLEAN:
            layout.push(
                <Grid key={name} item xs={xs} sm={sm} style={gridStyle}>
                    <CheckboxField name={name} label={label} dialogName={dialogName}/>
                </Grid>
            );
            break;

        case TYPES.SELECT:
            layout.push(
                <Grid key={name} item xs={xs} sm={sm} style={gridStyle}>
                    <SelectField name={name} label={label} entityName={entityName} getValue={getValue}
                                 dialogName={dialogName}/>
                </Grid>
            );
            break;

        case TYPES.AUTOCOMPLETE:
            layout.push(
                <Grid key={name} item xs={xs} sm={sm} style={gridStyle}>
                    <AutocompleteField name={name} label={label} entityName={entityName}
                                       dialogName={dialogName}/>
                </Grid>
            );
            break;

        case TYPES.DADATA:
            layout.push(
                <Grid key={name} item xs={xs} sm={sm} style={gridStyle}>
                    <DadataField name={name} label={label} dialogName={dialogName}/>
                </Grid>
            );
            break;

        case TYPES.DATE:
            layout.push(
                <Grid key={name} item xs={xs} sm={sm} style={gridStyle}>
                    <DateField name={name} label={label} dialogName={dialogName}/>
                </Grid>
            );
            break;

        case TYPES.NUMBER:
            layout.push(
                <Grid key={name} item xs={xs} sm={sm} style={gridStyle}>
                    <NumberField name={name} label={label} interval={interval} dialogName={dialogName}
                                 allowNegative={allowNegative}/>
                </Grid>
            );
            break;

        case TYPES.DECIMAL:
            layout.push(
                <Grid key={name} item xs={xs} sm={sm} style={gridStyle}>
                    <DecimalField name={name} label={label} dialogName={dialogName}/>
                </Grid>
            );
            break;

        case TYPES.FILE:
            layout.push(
                <Grid key={name} item xs={xs} sm={sm} style={gridStyle}>
                    <FileField name={name} label={label} dialogName={dialogName}/>
                </Grid>
            );
            break;

        default:
            layout.push(
                <Grid key={name} item xs={xs} sm={sm} style={gridStyle}>
                    <TextField name={name} label={label} dialogName={dialogName} multiline={multiline}/>
                </Grid>
            );
    }

    return layout;
}

function renderTabs(tabs, dialog, tabValue, handleTabChange, entity, fields, name) {

    if (tabs) {
        let visibleTabs;
        visibleTabs = dialog.mode === 'add' ? tabs.filter(i => i.name === 'details') :
            dialog.mode === 'edit' ? [...tabs] : [];

        return (
            <Grid>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="detail-tabs">
                    {visibleTabs.map((item, idx) => (
                        <Tab label={item.title} {...a11yProps(idx)} />
                    ))}
                </Tabs>
                {visibleTabs.map((item, idx) => (
                    <CustomTabPanel key={idx} value={tabValue} index={idx}>
                        {cloneElement(item.component, {
                            parentParams: {name: entity, id: dialog.id},
                            dialogName: name,
                            ...(item.name === 'details' && {fields}),
                        })}
                    </CustomTabPanel>
                ))}
            </Grid>
        )
    }
}

function a11yProps(index) {
    return {
        id: `detail-tab-${index}`,
        'aria-controls': `detail-tabpanel-${index}`,
        key: index,
    };
}

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`detail-tabpanel-${index}`}
            aria-labelledby={`detail-tab-${index}`}
            {...other}
        >
            {value === index && (children)}
        </div>
    );
}

function prepareToSaveDialogData(values, fields) {
    let outputValues = {};
    for (let key in values) {
        const field = fields.find(f => f.name === key);
        if (field.type === TYPES.SELECT || field.type === TYPES.AUTOCOMPLETE || field.type === TYPES.FILE) {
            outputValues[`${key}Id`] = values[key]
        } else outputValues[key] = values[key]
    }
    return outputValues;
}

function calcChangedData(dialog) {
    const initial = dialog.initialValues;
    const values = dialog.values;
    let changed = {};
    for (let key in initial) {
        if (values[key] !== initial[key]) {
            changed[key] = values[key]
        }
    }
    return changed
}


export default function DataDialog(props) {

    const {addTitle, editTitle, entity, fields, name, maxWidth, tabs, parentParams} = props;
    const [tabValue, setTabValue] = React.useState(0);
    const dialog = useSelector(selectDialogData(name));
    const row = useSelector(selectEntityDataById(entity, dialog?.id));
    const dispatch = useDispatch();
    const params = useSelector(selectParams(parentParams ? `${parentParams.name}-${entity}Table` : `${entity}Table`))
    const user = useSelector(selectUser)
    const socket = useContext(SocketContext)

    useEffect(() => {
        if (dialog?.mode) {
            // загрузка списков (без повторов)
            loadSelectFieldsEntities(fields, dispatch).then();
            let values = {};
            if (dialog.mode === 'edit') {
                fields.forEach(field => {
                    values[field.name] = [TYPES.SELECT, TYPES.FILE].includes(field.type) ? row[field.name]?.id : row[field.name];

                    if (field.type === TYPES.FILE) {
                        values[field.name] && loadOneRecordById(dispatch, 'file', values[field.name]).then();
                    }
                });
                // заполнение копии в initialValues для последующего поиска изменений
                dispatch(setFieldInitialValues(name, values));
            }
            // заполнение начальных данных если указаны в шаблоне
            else if (dialog.mode === 'add') {
                fields.forEach(field => {
                    values[field.name] = typeof field.initialValue === 'function' ?
                        field.initialValue(dialog.mode, user) : field.initialValue
                });
            }
            // заполнение полей диалога данными из записи
            dispatch(setFieldValues(name, values));
        }
    }, [dialog?.mode]);
    const getTitle = (mode) => {
        switch (mode) {
            case 'add':
                return addTitle;
            case 'edit':
                return editTitle;
            default:
                return null;
        }
    };

    function handleSubmit() {
        try {
            if (dialog.mode === 'add' || dialog.mode === 'copy') {
                const outputValues = prepareToSaveDialogData(dialog.values, fields);
                let parentData = {}
                if (parentParams) {
                    parentData = {[parentParams.name + "Id"]: parentParams.id}
                }
                saveData(entity, {...outputValues, ...parentData})
                    .then((result) => {
                            if (result.status === 201) {
                                socket.emit("change-entity", {data: result.data, entity, mode: 'add', userId: user.id})
                                loadData(entity, params).then(data => dispatch(setEntity({name: entity, data})))
                                dispatch(setOpenDialog(name, {isOpen: false, mode: null, name}));
                            } else {
                                dispatch(setOpenAlertDialog({content: result.data.message}))
                            }
                        }
                    );
            } else if (dialog.mode === 'edit') {
                const changedData = calcChangedData(dialog);
                const outputValues = prepareToSaveDialogData(changedData, fields);
                updateData(entity, dialog.id, outputValues)
                    .then((result) => {
                        if (result.status === 200) {
                            socket.emit("change-entity", {data: result.data, entity, mode: 'edit', userId: user.id})
                            loadData(entity, params).then(data => dispatch(setEntity({name: entity, data})));
                            dispatch(setOpenDialog(name, {isOpen: false, mode: null, name}));
                        } else {
                            dispatch(setOpenAlertDialog({content: result.data.message}))
                        }
                    });
            }
        } catch (e) {
            console.log(e)
        }
    }

    function handleCancel() {
        dispatch(setOpenDialog(name, {isOpen: false}))
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div>
            {dialog &&
                <Dialog open={dialog.isOpen} fullWidth={true} maxWidth={maxWidth}>
                    <DialogTitle>{getTitle(dialog.mode)}</DialogTitle>
                    <DialogContent style={{paddingTop: 12}}>

                        <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 1, md: 1, xl: 2}}>
                            {fields.filter(field => !field.additional).reduce((layout, field) => renderField(layout, field, dialog.mode, name), [])}
                        </Grid>

                        {renderTabs(tabs, dialog, tabValue, handleTabChange, entity, fields, name)}

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleSubmit}>Сохранить</Button>
                        <Button variant="contained" onClick={handleCancel}>Отмена</Button>
                    </DialogActions>
                </Dialog>
            }

        </div>
    );
}
