import {createSlice} from '@reduxjs/toolkit'

const mainBarSlice = createSlice({
    name: 'mainBar',
    initialState: {},

    reducers: {
        setTableTitle(state, action) {
            state.tableTitle = action.payload.tableTitle;
            state.viewName = action.payload.viewName;
        },
    }
});

export const selectMainBarData = (state) => state?.mainBar;

export const {setTableTitle} = mainBarSlice.actions;

export default mainBarSlice.reducer;
