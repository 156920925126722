import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {DialogContentText} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectAlertDialog, setCloseAlertDialog} from "../../store/ModalSlice";


const AlertDialog = () => {
    const dialog = useSelector(selectAlertDialog)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setCloseAlertDialog());
    }

    return (
        <Dialog
            open={dialog.open || false}
            onClose={handleClose}
        >
            <DialogTitle>Внимание</DialogTitle>
            <DialogContent>
                <DialogContentText>{dialog.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus> OK </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;