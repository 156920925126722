import React from 'react';
import DataTableHeader from "./DataTableHeader/DataTableHeader";
import DataTableBody from "./DataTableBody/DataTableBody";
import {DIALOG_GRID_HEIGHT} from "../../../utils/constants";
import './DataTable.css'


export default function DataTable({
                                      name, columns, menuItems,
                                      columnOrder, columnWidths, columnHidden,
                                      entity, params, dialogName,
                                      parentParams, accessKey,
                                  }) {
    return (
        <div
            className={"table-container"}
            style={{ height: parentParams && DIALOG_GRID_HEIGHT }}
        >
            <table>
                <colgroup>
                    <col key={"toolbar"} style={{minWidth: 50, width: 50}}/>
                    {columnOrder && columnOrder.map(cOrder => {
                        const column = columnWidths.find(cWidth => cWidth.name === cOrder && !columnHidden.includes(cOrder))
                        return column && <col key={column.name} style={{minWidth: column.width, width: column.width}}/>
                    })}
                </colgroup>

                <DataTableHeader
                    name={name}
                    columnOrder={columnOrder}
                    columnWidths={columnWidths}
                    columnHidden={columnHidden}
                    columns={columns}
                    params={params}
                    entity={entity}
                    accessKey={accessKey}
                />
                <DataTableBody
                    name={name}
                    columns={columns}
                    menuItems={menuItems}
                    columnOrder={columnOrder}
                    columnHidden={columnHidden}
                    dialogName={dialogName}
                    params={params}
                    entity={entity}
                />
            </table>
        </div>
    )
}
