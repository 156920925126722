import LogoutIcon from '@mui/icons-material/Logout';
import ToolbarButton from "../../../components/ToolButton";
import './ChatToolbar.css'


const ChatToolbar = ({room}) => {

    function handleLogoutChat() {
        console.log('logoutChat')
    }

    return (
        <div className={"chat-toolbar-container"}>
            <div>Комната: {room}</div>
            <div className={"chat-tool-buttons-container"}>
                <ToolbarButton
                    onClick={handleLogoutChat}
                    tooltipTitle={'Выйти из чата'}
                    name="logoutChat"
                    icon={<LogoutIcon/>}
                />

            </div>
        </div>
    )
};

export default ChatToolbar;