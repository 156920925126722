import React, {useEffect, useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import {convertDatetoString} from "../../../utils/dateUtils";
import SelectTextFilter from "./SelectTextFilter";
import {loadSelectFieldsEntities} from "../../../http/helper";
import {useDispatch} from "react-redux";
import {setParameterDataTable} from "../../../store/DataSlice";
import {filterOperation as op} from "../../../utils/constants";
import {TYPES} from "../../../utils/constants";
import './styles.css'


const options = {
    [TYPES.DATE]: [op.ONE_DAY, op.DATE_RANGE],
    [TYPES.DECIMAL]: [op.EQUAL, op.NOT_EQUAL, op.GREATER_THAN, op.GREATER_THAN_OR_EQUAL, op.LESS_THAN, op.LESS_THAN_OR_EQUAL],
    [TYPES.TEXT]: [op.LIKE, op.NOT_LIKE],
    [TYPES.LINK]: [op.IN, op.NOT_IN],
}

const FilterListDialog = ({name, onSubmit, onCancel, messages, columns, columnOrder, columnHidden, columnSearch}) => {

    const [columnFilters, setColumnFilters] = useState()
    const dispatch = useDispatch();

    useEffect(() => {
        // заполнение данных по текущим фильтрам
        setColumnFilters(calcColumnFilters(columns, columnOrder, columnHidden, columnSearch, options))
    }, [])


    const calcColumnFilters = (columns, columnOrder, columnHidden, columnSearch, options) => {
        const filters = columnOrder
            .filter(cOrder => !columnHidden.includes(cOrder))
            .map(cOrder => {
                const column = columns.find(c => c.id === cOrder)
                const existFilter = columnSearch.find(c => c.id === cOrder)
                return {
                    id: column.id,
                    path: column.path,
                    label: column.label,
                    type: column.type,
                    entityName: column.entityName,
                    typeOptions: options[column.type],
                    operation: existFilter ? existFilter?.operation : options[column.type] ? options[column.type][0] : null,
                    value: existFilter?.value || ''
                }
            })
            .filter(c => c.typeOptions)
        // загрузка списков
        loadSelectFieldsEntities(filters, dispatch).then();
        return filters
    }

    const renderDialogTitle = (title) => {
        return (
            <div className={"dialogTitle"}>
                <DialogTitle>{title}</DialogTitle>
            </div>
        );
    }

    const renderItemContent = () => {

        const renderCondition = (filter) => {

            const handleChangeCondition = (e) => {
                const newFilters = [...columnFilters]
                newFilters.map(filter => {
                    if (filter.id === e.target.name) {
                        filter.operation = e.target.value
                    }
                    return filter
                })
                setColumnFilters(newFilters)
            }

            return (
                <TextField
                    value={filter.operation}
                    name={filter.id}
                    select
                    onChange={handleChangeCondition}
                    sx={{width: '100%'}}
                >
                    {filter.typeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {messages[option]}
                        </MenuItem>
                    ))}
                </TextField>
            )
        }

        const renderOutputValue = (filter) => {

            const handleChangeValueClick = (e) => {
                const newFilters = [...columnFilters]
                newFilters.map(filter => {
                    if (filter.id === e.target.name) {
                        filter.value = e.target.value
                    }
                    return filter
                })
                setColumnFilters(newFilters)
            }

            const handleChangeOneDateRangeClick = (e) => {
                const date = new Date(e.target.value)
                const newFilters = [...columnFilters]
                newFilters.map(filter => {
                    if (filter.id === e.target.name) {
                        filter.value = [convertDatetoString(date)]
                    }
                    return filter
                })
                setColumnFilters(newFilters)
            }

            const handleChangeDateRangeFromClick = (e) => {
                const fromValue = new Date(e.target.value)
                const newFilters = [...columnFilters]
                newFilters.map(filter => {
                    if (`${filter.id}-from` === e.target.name) {
                        if (filter.value === '') {
                            filter.value = ['', '']
                        }
                        filter.value = [convertDatetoString(fromValue), filter.value[1]]
                    }
                    return filter
                })
                setColumnFilters(newFilters)
            }

            const handleChangeDateRangeToClick = (e) => {
                const toValue = new Date(e.target.value)
                const newFilters = [...columnFilters]
                newFilters.map(filter => {
                    if (`${filter.id}-to` === e.target.name) {
                        if (filter.value === '') {
                            filter.value = ['', '']
                        }
                        filter.value = [filter.value[0], convertDatetoString(toValue)]
                    }
                    return filter
                })
                setColumnFilters(newFilters)
            }

            const handleChangeSelectClick = (name, value) => {
                const newFilters = [...columnFilters]
                newFilters.map(filter => {
                    if (filter.id === name) {
                        filter.value = value
                    }
                    return filter
                })
                setColumnFilters(newFilters)
            }

            switch (filter.type) {
                case TYPES.DECIMAL:
                    return <TextField
                        name={filter.id}
                        value={filter.value}
                        type="number"
                        onChange={handleChangeValueClick}
                        sx={{width: '100%'}}
                    />
                case TYPES.DATE: {
                    if (filter.operation === op.ONE_DAY) {
                        return <TextField
                            name={filter.id}
                            value={filter?.value[0] || ''}
                            type="date"
                            onChange={handleChangeOneDateRangeClick}
                            sx={{width: '100%'}}
                        />
                    } else  //if (filter.operation === op.DATE_RANGE)
                    {
                        return <Grid container>
                            <Grid item>
                                <TextField
                                    name={`${filter.id}-from`}
                                    value={filter?.value[0] || ''}
                                    type="date"
                                    onChange={handleChangeDateRangeFromClick}
                                    error={filter.error}
                                />
                            </Grid>
                            <Grid item>
                                <span style={{padding: '10px'}}>по</span>
                                <TextField
                                    name={`${filter.id}-to`}
                                    value={filter?.value[1] || ''}
                                    type="date"
                                    onChange={handleChangeDateRangeToClick}
                                    error={filter.error}
                                />
                            </Grid>
                        </Grid>
                    }
                }
                case TYPES.LINK:
                    return <SelectTextFilter
                        onChange={handleChangeSelectClick}
                        filter={filter}
                    />
                default:
                    return <TextField
                        name={filter.id}
                        value={filter.value}
                        type={"text"}
                        onChange={handleChangeValueClick}
                        filter={filter}
                        sx={{width: '100%'}}
                    />
            }
        }
        return (
            <DialogContent className={"dialogContent"}>
                <div style={{padding: '20px'}}>
                    <table>
                        <tbody>
                        {columnFilters && columnFilters.map(filter =>
                            <tr key={filter.id}>
                                <td><span> {`${filter.label}:`}</span></td>
                                <td>{renderCondition(filter)}</td>
                                <td>{renderOutputValue(filter)}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
        )
    }

    const renderActions = () => {

        const handleSubmit = () => {
            const newColumnFilters = columnFilters.map(item => {
                if (item.type === 'date' && item.operation === 'dateRange') {
                    if (item.value === '' || (item.value[0] === '' && item.value[1] === '')) {
                        item.error = false
                    } else item.error = isNaN(Date.parse(item.value[0])) || isNaN(Date.parse(item.value[1]));
                }
                return item
            })
            setColumnFilters(newColumnFilters)

            // проверить что нет ошибок
            const errors = []
            newColumnFilters.forEach(item => item.error && errors.push(item.id))

            if (errors.length === 0) {
                const newColumnSearch = columnFilters
                    .filter(item => item.value !== '')
                    .map(item => ({
                            id: item.id,
                            path: item.path,
                            operation: item.operation,
                            value: item.value,
                            type: item.type,
                        })
                    )
                dispatch(setParameterDataTable(name, {columnSearch: newColumnSearch}))
                onSubmit(newColumnSearch)
            }
        }

        return (
            <DialogActions sx={{paddingRight: '12px'}}>
                <Button onClick={handleSubmit}>Применить</Button>
                <Button onClick={onCancel}>Отмена</Button>
            </DialogActions>
        )
    }

    return (
        <Dialog
            onClose={onCancel}
            open={true}
            maxWidth={'md'}
            className={"paper"}
        >
            {renderDialogTitle('Настройка фильтра')}
            {renderItemContent()}
            {renderActions()}
        </Dialog>)
};


export default FilterListDialog
