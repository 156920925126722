import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";

import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {AttachFile, Clear} from "@mui/icons-material";
import Input from "@mui/material/Input";

import {selectEntityByFieldName, setFieldValue} from "../../store/DialogSlice";
import {addEntity, removeEntity} from "../../store/DataSlice";
import {deleteData, uploadFile} from "../../http/dataAPI";


const FileField = ({name, label, dialogName}) => {

  const dispatch = useDispatch();
  const file = useSelector(selectEntityByFieldName(dialogName, name, "file"));
  const fileRef = useRef();

  function handleClearButtonClick() {
    fileRef.current.value = null
    deleteData('file', file.id).then(response => {
      dispatch(removeEntity({name: 'file', id: file.id}));
      dispatch(setFieldValue(dialogName, name, null))
    })
  }

  function handleClearButtonMouseDown(e) {
    e.preventDefault();
  }

  function handleAttachButtonClick() {
    fileRef.current.click()
  }

  function handleFileInputChange(e) {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file).then((response) => {
        const id = response.data.id;
        const fieldName = response.data.name;
        dispatch(addEntity({name: 'file', data: {id, name: fieldName}}));
        dispatch(setFieldValue(dialogName, name, id))
      })
    }
  }

  return (
    <>
      <Input
        type="file"
        name={`${name}-native`}
        inputRef={fileRef}
        onChange={(e) => handleFileInputChange(e)}
        sx={{display: "none"}}
      />
      <TextField
        autoComplete="off"
        margin="dense"
        id={name}
        name={name}
        label={label}
        type="text"
        fullWidth
        variant="outlined"
        value={file?.name || ''}
        InputProps={{
          readOnly: true,
          endAdornment: <InputAdornment position="end">
            {file?.name ? <IconButton
                onClick={handleClearButtonClick}
                onMouseDown={handleClearButtonMouseDown}
              > <Clear/> </IconButton>
              :
              <IconButton onClick={handleAttachButtonClick}> <AttachFile/> </IconButton>
            }
          </InputAdornment>,
        }}
      />
    </>
  );
};

export default FileField;
