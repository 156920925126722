import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import {useDispatch} from "react-redux";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

import {registration, login} from "../../http/userAPI";
import {setUser} from "../../store/UserSlice";
import {SocketContext} from "../../context/socket";


export default function AuthDialog() {
    const [userLogin, setUserLogin] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [error, setError] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const socket = useContext(SocketContext);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleRegistration = async () => {
        try {
            const user = await registration(userLogin, password);
            setError("");
            dispatch(setUser(user));
            navigate('/agreements');
        } catch (e) {
            setError(e.response.data.message);
        }
    };

    const handleLogin = async () => {
        try {
            const user = await login(userLogin, password);
            setError("");
            dispatch(setUser(user));
            socket.emit("login",  {user, socketID: socket.id})
            navigate('/agreements');
        } catch (e) {
            setError(e.response.data.message);
        }
    };

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            await handleLogin()
        }
    }

    return (
        <div>
            <Dialog open={true}>
                <DialogTitle>Личный кабинет</DialogTitle>
                <div style={{display: "flex", justifyContent: 'center', color: 'red'}}>
                    {error}
                </div>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="login"
                        name="login"
                        label="Логин"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={userLogin}
                        onChange={e => setUserLogin(e.target.value)}
                        helperText={"Введите логин"}
                        inputProps={{
                            autoComplete: 'off'
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        name="password"
                        label="Пароль"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        variant="outlined"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        helperText={"Введите пароль"}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"> <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton></InputAdornment>,
                            autoComplete: "off"
                        }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogin}>Войти</Button>
                    {/*<Button onClick={handleRegistration}>Регистрация</Button>*/}
                </DialogActions>
            </Dialog>
        </div>
    );
}
