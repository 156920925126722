import React from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';


export const editActionItem = {
    id: 'edit',
    label: 'Изменить',
    icon: <EditIcon/>,
    // образец
    // hidden: (row) => {
    //     return row.status.id === 3; // Выполнен
    // },
};

export const deleteActionItem = {
    id: 'delete',
    label: 'Удалить',
    icon: <DeleteIcon/>,
};

export const logOutActionItem = {
    id: 'logOut',
    label: 'Выход',
    icon: <LogoutIcon/>,
};


