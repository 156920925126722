import {TYPES} from "../../../utils/constants";


export const fields = [
  {
    name: 'date',
    label: 'Дата',
    type: TYPES.DATE,
  },
  {
    name: 'amount',
    label: 'Сумма',
    type: TYPES.DECIMAL,
    interval: {min: 0},
  },
  {
    name: 'description',
    label: 'Примечание',
    multiline: true,
    grid: {xs: 12, sm: 12}
  },
];

