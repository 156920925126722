import {addButton, deleteButton, editButton} from "../../common/buttons";
import {COLUMN_WIDTHS, settings, SORT, TYPES} from "../../../utils/constants";
import {deleteActionItem, editActionItem} from "../../common/menuItems";


export const columns = [
    {
        id: 'status',
        path: 'status.name',
        label: 'Состояние',
        type: TYPES.LINK,
        entityName: 'status',
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'responsible',
        path: 'responsible.name',
        label: 'Ответственный',
        type: TYPES.LINK,
        entityName: 'employee',
        minWidth: COLUMN_WIDTHS.LARGE
    },
    {
        id: 'description',
        label: 'Что делать',
        type: TYPES.TEXT,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'startDate',
        label: 'Приступить',
        type: TYPES.DATE,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'endDate',
        label: 'Завершить к',
        type: TYPES.DATE,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'lastAction',
        label: 'Отчет о выполнении',
        type: TYPES.TEXT,
        minWidth: COLUMN_WIDTHS.LARGE
    },
    {
        id: 'taskAuthor',
        path: 'taskAuthor.name',
        label: 'Автор',
        type: TYPES.LINK,
        entityName: 'employee',
        minWidth: COLUMN_WIDTHS.LARGE
    },
    {
        id: 'created',
        label: 'Создана',
        type: TYPES.DATE,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'updated',
        label: 'Изменена',
        type: TYPES.DATE,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
];

export const tableButtons = (dialogName) => [
    {...addButton, dialogName}, {...editButton, dialogName}, {...deleteButton, dialogName}
];

export const tableMenuItems = (dialogName) => [
    {...editActionItem, dialogName}, {...deleteActionItem, dialogName}
]

export const sorting = {column: 'created', direction: SORT.DESC}

export function getTableFeatures() {
    return [
        {name: settings.HIDDING, value: true},
        {name: settings.FULL_TEXT_SEARCH, value: true},
        {name: settings.SEARCHING, value: true},
        {name: settings.REORDERING, value: true},
        {name: settings.RESIZING, value: true},
    ];
}
