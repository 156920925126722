import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import TextField from "@mui/material/TextField/TextField";
import MenuItem from "@mui/material/MenuItem";
import {selectDialogDataValueByName, setFieldValue} from "../../store/DialogSlice";
import {selectEntityData} from "../../store/DataSlice";


const SelectField = ({name, label, entityName, getValue, dialogName}) => {

    const dispatch = useDispatch();
    const options = useSelector(selectEntityData(entityName)) || [];
    const value = useSelector(selectDialogDataValueByName(dialogName, name)) || "";

    const handleChange = (e) => {
        dispatch(setFieldValue(dialogName, name, e.target.value))
    };

    return (
        <TextField
            id={name}
            select
            label={label}
            fullWidth
            style={{marginTop: 8, marginBottom: 4}}
            value={options.length > 0 ? value : ''}
            onChange={handleChange}
        >
            {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {getValue ? getValue(option) : option.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default SelectField;
