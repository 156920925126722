import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import MenuItem from "@mui/material/MenuItem";
import {Menu} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import Divider from "@mui/material/Divider";

import {selectEntityData} from "../../../../store/DataSlice";
import Button from "@mui/material/Button";
import '../styles.css'

const SelectTextFilter = ({onChange, filter}) => {

    const [anchorEl, setAnchorEl] = useState()
    const [selectedAll, setSelectedAll] = useState(false)
    const [entity, setEntity] = useState()
    const [outputValue, setOutputValue] = useState()
    const options = useSelector(selectEntityData(filter.entityName))

    useEffect(() => {
        if (options) {
            const initEntity = options.map(opt => {
                return {...opt, selected: filter.value.includes(opt.name)}
            })
            setEntity(initEntity)
        }
        setOutputValue(filter.value)
    }, [options, filter])


    const handleMenuClose = () => setAnchorEl(null)
    const handleClick = (e) => setAnchorEl(e.currentTarget)

    const handleSelectAllClick = (e) => {
        const checked = e.target.checked
        const newEntity = entity.map(item => ({...item, selected: checked}))
        setSelectedAll(checked)
        setEntity(newEntity)
    }

    const handleMenuItemClick = (menuItem) => {
        let newEntity = entity.map(item => {
            if (item.id === menuItem.id) {
                item.selected = !item.selected
            }
            return item
        })
        setEntity(newEntity)
    }

    const handleOKClick = () => {
        const _outputValue = entity.filter(item => item.selected).map(item => item.name).join(',')
        setOutputValue(_outputValue)
        setAnchorEl(null)
        onChange(filter.id, _outputValue)
    }

    return (
        <div>
            <OutlinedInput
                onClick={handleClick}
                value={outputValue || ''}
                fullWidth
                size={"small"}
            />
            {entity &&
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem key={0}>
                        <Checkbox
                            color="primary"
                            checked={selectedAll}
                            onChange={handleSelectAllClick}
                        /> Выделить все
                    </MenuItem>
                    <Divider/>
                    {entity && entity.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                            <Checkbox
                                color="primary"
                                checked={item.selected}
                                onChange={() => handleMenuItemClick(item)}
                            />
                            {item.name}
                        </MenuItem>
                    ))}
                    <div className={"selectTextFilterButtonsContainer"}>
                        <Button color="primary" onClick={handleOKClick}>OK</Button>
                        <Button color="primary" onClick={handleMenuClose}>Отмена</Button>
                    </div>
                </Menu>}
        </div>
    );
};

export default SelectTextFilter;