import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import {useDispatch, useSelector} from "react-redux";
import {selectDialogDataValueByName, setFieldValue} from "../../store/DialogSlice";
import {FormControlLabel} from "@mui/material";

const CheckboxField = ({name, label, dialogName}) => {

    const dispatch = useDispatch();

    const value = useSelector(selectDialogDataValueByName(dialogName, name)) || false;
    const handleChange = (e) => dispatch(setFieldValue(dialogName, name, e.target.checked));


    return (
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    checked={value}
                    onChange={handleChange}
                />
            }
        />
    );
}

export default CheckboxField;