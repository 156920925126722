import React from 'react';
import TextField from '@mui/material/TextField';
import {useDispatch, useSelector} from "react-redux";
import {selectDialogDataValueByName, setFieldValue} from "../../store/DialogSlice";


const TextFieldSimple = ({name, label, dialogName, multiline}) => {
  const dispatch = useDispatch();
  const value = useSelector(selectDialogDataValueByName(dialogName, name)) || '';

  const handleChange = (e) => dispatch(setFieldValue(dialogName, name, e.target.value));

  return (
    <TextField
      autoComplete="off"
      margin="dense"
      id={name}
      name={name}
      label={label}
      type="text"
      fullWidth
      variant="outlined"
      value={value}
      onChange={handleChange}
      multiline={multiline}
      maxRows={4}
    />
  );
};

export default TextFieldSimple;
