import {TYPES} from "../../../utils/constants";


export const fields = [
  {
    name: 'number',
    label: 'Номер',
  },
  {
    name: 'date',
    label: 'Дата',
    type: TYPES.DATE,
  },
  {
    name: 'service',
    label: 'Услуга',
    grid: {xs: 12, sm: 12}
  },
  {
    name: "count",
    label: "Количество",
    type: TYPES.NUMBER,
    allowNegative: false,
    grid: {xs: 4, sm: 4},
  },
  {
    name: "price",
    label: "Цена",
    type: TYPES.DECIMAL,
    interval: {min: 0},
    grid: {xs: 4, sm: 4},
  },
  {
    name: "amount",
    label: "Сумма",
    type: TYPES.DECIMAL,
    interval: {min: 0},
    grid: {xs: 4, sm: 4},
  },
  {
    name: 'description',
    label: 'Примечание',
    multiline: true,
    grid: {xs: 12, sm: 12}
  },
];

