import {useSelector} from "react-redux";

import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import {selectMainBarData} from "../../../store/MainBarSlice";
import NotificationButton from "../NotificationButton/NotificationButton";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {DRAWER_WIDTH} from "../../../utils/constants";


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const MainBar = ({drawerOpen, onLogout, open, user}) => {

    const mainBarData = useSelector(selectMainBarData);

    return (
        <AppBar position="fixed" open={open}>
            <Toolbar style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Grid style={{display: "flex", alignItems: "center"}}>
                    {!open && <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={drawerOpen}
                        edge="start"
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>}
                    <Typography variant="h6" noWrap component="div">
                        {mainBarData.tableTitle}
                    </Typography>
                </Grid>
                <Grid style={{display: "flex", alignItems: "center",}}>
                    <Grid style={{paddingRight: 10, display: "flex", alignItems: "center"}}>
                        <AccountCircleIcon/>
                        <Typography variant="h5" noWrap component="div" style={{paddingLeft: '5px'}}>
                            {user.login}
                        </Typography>
                    </Grid>
                    <Grid>
                        <NotificationButton/>
                    </Grid>
                    <Grid>
                        <IconButton onClick={onLogout}>
                            <LogoutIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default MainBar;