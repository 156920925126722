export const AGREEMENTS_ROUTE = '/agreements';
export const CUSTOMERS_ROUTE = '/customers';
export const EMPLOYEES_ROUTE = '/employees';
export const TASKS_ROUTE = '/tasks';
export const USERS_ROUTE = '/users';
export const NOTIFICATIONS_ROUTE = '/notifications';
export const CHAT_ROUTE = '/chat';
export const LOGIN_ROUTE = '/login';


/* ENTITIES */
export const ENTITIES = Object.freeze({
    AGREEMENT: 'agreement',
    CUSTOMER: 'customer',
    CONTRACTOR: 'contractor',
    EMPLOYEE: 'employee',
    USER: 'user',
    ROLE: 'role',
    STATUS: 'status',
    TYPE: 'type',
    TASK: 'task',
    EXECUTION_PLAN: 'executionPlan',
    EXECUTION_FACT: 'executionFact',
    PAYMENT_PLAN: 'paymentPlan',
    PAYMENT_FACT: 'paymentFact',
    BILL: 'bill',
    FILE: 'file',
    NOTIFICATION: 'notification',
});


/* COLUMN_WIDTHS */
export const COLUMN_WIDTHS = Object.freeze({
    SMALL: 75,
    MEDIUM: 150,
    LARGE: 250,
});


/* TYPES */
export const TYPES = Object.freeze({
    TEXT: 'text',
    DATE: 'date',
    SELECT: 'select',
    AUTOCOMPLETE: 'autocomplete',
    DADATA: 'dadata',
    NUMBER: 'number',
    DECIMAL: 'decimal',
    LINK: 'link',
    FILE: 'file',
    BOOLEAN: 'boolean',
});

/* SORT */
export const SORT = Object.freeze(({
    ASC: 'asc',
    DESC: 'desc',
}))

/* ROLES */
export const ROLES = Object.freeze(({
    ADMIN: 1,
    USER: 2,
    MANAGER: 3,
}))

/* MODES */
export const MODES = Object.freeze(({
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
}))

/* SETTINGS TYPES */
export const settings = Object.freeze({
    PAGING: 'paging',
    VIRTUAL: 'virtual',
    HIDDING: 'hidding',
    REORDERING: 'reordering',
    RESIZING: 'resizing',
    FULL_TEXT_SEARCH: 'fullTextSearch',
    COLUMN_SEARCH: 'columnSearch',
    SEARCHING: 'searching', // удалить
    CURRENT_PAGE: 'currentPage',
    PAGE_SIZE: 'pageSize',
});


/* FILTER OPERATIONS  */
export const filterOperation = Object.freeze({
    IN: 'in',
    NOT_IN: 'notIn',
    LIKE: 'iLike',
    NOT_LIKE: 'notILike',
    EQUAL: 'eq',
    GREATER_THAN: 'gt',
    GREATER_THAN_OR_EQUAL: 'gte',
    LESS_THAN: 'lt',
    LESS_THAN_OR_EQUAL: 'lte',
    NOT_EQUAL: 'ne',
    STARTS_WITH: 'startsWith',
    ENDS_WITH: 'endsWith',
    SUBSTRING: 'substring',
    DATE_RANGE: 'dateRange',
    ONE_DAY: 'oneDay',
});


export const getTableMessages = {
    noData: 'Нет данных',
};

export const getFilterMessages = {
    [filterOperation.IN]: 'Содержит',
    [filterOperation.NOT_IN]: 'Не содержит',
    [filterOperation.LIKE]: 'Содержит',
    [filterOperation.NOT_LIKE]: 'Не содержит',
    [filterOperation.EQUAL]: 'Равен',
    [filterOperation.GREATER_THAN]: 'Больше чем',
    [filterOperation.GREATER_THAN_OR_EQUAL]: 'Больше чем или равен',
    [filterOperation.LESS_THAN]: 'Меньше чем',
    [filterOperation.LESS_THAN_OR_EQUAL]: 'Меньше чем или равен',
    [filterOperation.NOT_EQUAL]: 'Не равен',
    [filterOperation.SUBSTRING]: 'Содержит',
    [filterOperation.ENDS_WITH]: 'Заканчивается с',
    [filterOperation.STARTS_WITH]: 'Начинается с',
    [filterOperation.DATE_RANGE]: 'Период',
    [filterOperation.ONE_DAY]: 'День',
};


export const DRAWER_WIDTH = 240;
export const MAIN_HEIGHT_OFFSET = 96
export const DIALOG_GRID_HEIGHT = 300;
export const SETTINGS_CONFIG_NAME = "TableSettings"