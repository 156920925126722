import axios from "axios";
import {$authHost} from "./index";
import {filterOperation as op} from "../utils/constants";
import {convertDatetoString} from "../utils/dateUtils";

export function checkStatus(response) {
    return response
}

export function checkErrorStatus(error) {

    const err = JSON.parse(error.request.response);
    console.log(error.request.status, err.message);
    return error.response
}

const getFilterBy = (filterBy, parent) => {
    let filter = {};
    if (filterBy) {
        filterBy.forEach(f => {
            if ([op.IN, op.NOT_IN].includes(f.operation)) {
                filter[`${f.path || f.id}.${f.operation}`] = f.value.split(',')
            } else if (f.type === 'date') {
                if (f.operation === op.DATE_RANGE) {
                    const toDate = new Date(f.value[1])
                    toDate.setDate(toDate.getDate() + 1)
                    filter[`${f.path || f.id}.between`] = [f.value[0], convertDatetoString(toDate)]
                } else if (f.operation === op.ONE_DAY) {

                    const toDate = new Date(f.value[0])
                    toDate.setDate(toDate.getDate() + 1)
                    filter[`${f.path || f.id}.between`] = [f.value[0], convertDatetoString(toDate)]

                }
            } else filter[`${f.path || f.id}.${f.operation}`] = f.value
        })
    }
    if (parent) {
        filter[`${parent.id}.${parent.operation}`] = parent.value
    }
    if (Object.keys(filter).length === 0) {
        return null
    }
    return JSON.stringify(filter)
}

export const loadData = async (entity, props) => {
    const params = {}
    if (props) {
        const {pageSize, pageNumber, sorting, filters, filterBy, search} = props
        params.pageSize = pageSize
        params.pageNumber = pageNumber
        params.order = sorting ? Object.values(sorting).join(' ') : null
        params.filters = filters && filters.length > 0 ? filters.join(',') : null
        params.search = search
        params.filterBy = getFilterBy(filterBy, props?.parent)
    }
    return await (await $authHost.get(`api/${entity}`, {params})).data;
};

export const loadDataById = async (entity, id) => {
    const {data} = await $authHost.get(`api/${entity}/${id}`);
    return data
};

export const saveData = async (entity, values) => {
    return await $authHost.post(`api/${entity}`, values).then(checkStatus, checkErrorStatus)
};

export const updateData = async (entity, id, values) => {
    return await $authHost.patch(`api/${entity}/${id}`, values).then(checkStatus, checkErrorStatus)
};

export const deleteData = async (entity, id) => {
    return await $authHost.delete(`api/${entity}/${id}`).then(checkStatus, checkErrorStatus)
};

export const downloadFile = async ({id, name}) => {
    await $authHost.get(`api/file/download/${id}`, {responseType: "blob"}).then(
        (response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
    ).then(checkStatus, checkErrorStatus);
};

export const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return await $authHost.post('api/file/upload', formData)
};

export function getSuggestions(searchQuery) {
    return axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio', {
        query: searchQuery,
        parts: ['SURNAME', 'NAME', 'PATRONYMIC'],
        count: 5,
    }, {
        headers: {
            Authorization: 'Token c325231f829d310a725a2257452332671bb3a74e',
        },
    });
}

export const loadUnreadNotificationsCount = async () => {
    const {data} = await $authHost.get('api/notification/unreadCount');
    return data
};

export const markReadNotifications = async () => {
    return await $authHost.patch(`api/notification/mark-read`).then(checkStatus, checkErrorStatus)
};

export const deleteNotifications = async () => {
    return await $authHost.delete(`api/notification`).then(checkStatus, checkErrorStatus)
};
