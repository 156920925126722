import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {SocketContext, socket} from "./context/socket";
import App from './App';
import store from './store'
import './index.css';


const root = createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <SocketContext.Provider value={socket}>
                < App/>
            </SocketContext.Provider>
        </Provider>
    </BrowserRouter>)
;
