import {addButton, deleteButton, editButton} from "../../common/buttons";
import {COLUMN_WIDTHS, settings, SORT, TYPES} from "../../../utils/constants";
import {deleteActionItem, editActionItem} from "../../common/menuItems";


export const columns = [
    {
        id: 'number',
        label: 'Номер',
        type: TYPES.DECIMAL,
        minWidth: COLUMN_WIDTHS.SMALL
    },
    {
        id: 'date',
        label: 'Дата',
        type: TYPES.DATE,
        minWidth: COLUMN_WIDTHS.MEDIUM
    },
    {
        id: 'service',
        label: 'Услуга',
        type: TYPES.TEXT,
        minWidth: COLUMN_WIDTHS.LARGE
    },
    {
        id: 'count',
        label: 'Количество',
        type: TYPES.DECIMAL,
        minWidth: COLUMN_WIDTHS.SMALL
    },
    {
        id: 'price',
        label: 'Цена',
        type: TYPES.DECIMAL,
        minWidth: COLUMN_WIDTHS.SMALL
    },
    {
        id: 'amount',
        label: 'Сумма',
        type: TYPES.DECIMAL,
        minWidth: COLUMN_WIDTHS.SMALL
    },
    {
        id: 'description',
        label: 'Примечание',
        type: TYPES.TEXT,
        minWidth: COLUMN_WIDTHS.LARGE
    },
];


export const tableButtons = (dialogName) => [
    {...addButton, dialogName}, {...editButton, dialogName}, {...deleteButton, dialogName}
];

export const tableMenuItems = (dialogName) => [
    {...editActionItem, dialogName}, {...deleteActionItem, dialogName}
]
export const sorting = {column: 'id', direction: SORT.ASC}

export function getTableFeatures() {
    return [
        {name: settings.HIDDING, value: true},
        {name: settings.FULL_TEXT_SEARCH, value: true},
        {name: settings.SEARCHING, value: true},
        {name: settings.REORDERING, value: true},
        {name: settings.RESIZING, value: true},
    ];
}